import { Navigate } from "react-router-dom";
import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";

const cookies = new Cookies();

const PrivateRoute = ({ children, roles }) => {
    const loggedIn = cookies.get('token');

    if(!loggedIn) {
        return <Navigate to="/" />
    }

    if(jwtDecode(loggedIn).name === undefined){
        return <Navigate to="/register" />
    }

    if(roles.indexOf(jwtDecode(loggedIn).role) === -1){
        return <Navigate to="/questionnaires" />
    }

    return children
}

export default PrivateRoute;