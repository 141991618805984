import ModalTemplate from "./ModalTemplate.js";
import styled from "styled-components";

const AttributeInfo = ({ oldQuiz, showModal, setShowModal }) => {
  return (
    <ModalTemplate showModal={showModal} setShowModal={setShowModal}>
      {oldQuiz ? (
        <>
          <h1>The Attributes</h1>
          <p>
            In the digital space, certain attributes are widely recognised as
            essential for success. These attributes contribute to a well-rounded
            skill set that goes beyond technical prowess, fostering an
            environment conducive to innovation, growth, and adaptability.
          </p>

          <ul>
            <li>
              <b>Communication and Empathy:</b> Effective communication is
              pivotal in the digital domain, where collaborative projects and
              diverse teams are the norms. Empathy enhances communication by
              fostering an understanding of various perspectives, leading to
              more effective and meaningful connections.
            </li>
            <li>
              <b>Creative and Flexible:</b> Creativity and flexibility are
              invaluable in navigating the ever-evolving landscape of digital
              technology. They enable professionals to adapt to changes and
              devise innovative solutions to complex challenges, thereby driving
              business growth and success.
            </li>
            <li>
              <b>Curious and Inquisitive:</b> Curiosity is a critical trait for
              digital professionals. It leads to better problem-solving, more
              innovative solutions, and a greater capacity to adapt to new
              challenges. A culture of curiosity in a company has been linked to
              business growth, as it encourages employees to seek better
              solutions and approaches.
            </li>
            <li>
              <b>Detailed and Technically Minded:</b> Attention to detail and
              technical acumen are fundamental in the digital space, where
              precision and expertise play crucial roles in project success and
              innovation.
            </li>
            <li>
              <b>Efficiency and Improvement:</b> The ability to work efficiently
              and continually seek improvement is vital in a fast-paced digital
              environment. This approach ensures that projects are executed
              effectively and that processes are constantly refined for better
              outcomes.
            </li>
            <li>
              <b>Order and Logic:</b> Logical thinking and a structured approach
              are key in problem-solving and project planning in the digital
              sector. These skills help in creating clear, effective strategies
              and solutions.
            </li>
            <li>
              <b>Patient and Committed:</b> Patience and commitment are
              essential for long-term success in digital careers, where complex
              tasks and ongoing learning are part of the job.
            </li>
            <li>
              <b>Problem Solving:</b> Problem-solving skills are critical in the
              digital field, as they enable professionals to navigate and
              overcome the various challenges that arise in their work.
            </li>
            <li>
              <b>Resilient and Positive:</b> Resilience and a positive attitude
              help digital professionals to persevere through setbacks and
              maintain momentum in their projects and career growth.
            </li>
            <li>
              <b>Team Player and Collaborative:</b> Collaboration is a
              cornerstone of digital work. Being a team player facilitates
              shared success and fosters a productive, supportive work
              environment.
            </li>
          </ul>
          <p>
            These attributes together form a comprehensive skill set that is
            highly valued in the digital space, enabling professionals to thrive
            in diverse roles and adapt to the dynamic nature of the industry.
          </p>

          <h3>Sources</h3>
          <p>
            As well as being developed through feedback from employers and
            learners in the field, there are a number of articles who reference
            these attributes;
          </p>

          <SourcesList>
            <li>
              Northeastern University's discussion on digital communication,
              highlighting the importance of empathy in effective communication,
              from their article "What is Digital Communication? | In-Demand
              Skills and Careers".
            </li>
            <li>
              London School of Economics (LSE) Careers blog discussing the
              importance of adaptability and creative thinking in careers,
              emphasizing the value of these skills in decision making and
              problem-solving, from their article "The importance of applying
              adaptability and creative thinking in your career".
            </li>
            <li>
              Iconic Digital Agency's article on the importance of curiosity in
              business, discussing how a culture of curiosity drives business
              growth, fosters better solutions to company problems, and
              encourages flexible and innovative thinking, from their article
              "Why Curiosity is Good for Business (and how to foster it)".
            </li>
            <li>
              SEEK's article on the importance of attention to detail:
              "Attention to detail: What it is and why it's important".
            </li>
            <li>
              BetterUp's article on continuous improvement: "Continuous
              Improvement: 6 Stages to Follow and Why It's Important".
            </li>
            <li>
              MIT Press's book on applying predicate logic in computer science:
              "Essential Logic for Computer Science".
            </li>
            <li>
              YourCareerPathNow's guide on cultivating patience in a digital
              age: "Cultivating Patience in a Digital Age: A Guide to
              Professional Development".
            </li>
          </SourcesList>
        </>
      ) : (
        <>
            <h1>The Attributes</h1>
            <p>
                In the digital space, certain attributes are widely recognised as
                essential for success. These attributes contribute to a well-rounded
                skill set that goes beyond technical prowess, fostering an
                environment conducive to innovation, growth, and adaptability.
            </p>

            <h3>WAY: How people approach their work, their attitude, and motivation</h3>
            <ul>
                <li><b>Attributes:</b> Adaptability & Resilience, Time Management, Growth Mindset</li>
                <li><b>Summary:</b> The "Way" element captures an individual’s approach to work, focusing on their resilience, flexibility, and proactive mindset. This includes the ability to manage time efficiently, prioritise tasks, and pursue continuous personal growth, enabling them to navigate change and stay productive in dynamic digital environments.</li>
            </ul>
            <h3>KNOW: Knowledge-based traits relating to how people understand and learn</h3>
            <ul>
                <li><b>Attributes:</b> Curiosity, Attention to Detail</li>
                <li><b>Summary:</b> "Know" highlights the intellectual traits essential for grasping and applying complex concepts. Curiosity drives a desire for knowledge and improvement, while critical thinking and attention to detail support precise understanding and sound decision-making in problem-solving scenarios.</li>
            </ul>
            <h3>HUMAN: The way people interact and collaborate with others, teams, and customers</h3>
            <ul>
                <li><b>Attributes:</b> Communication Skills, Empathy, Team Collaboration</li>
                <li><b>Summary:</b> The "Human" element focuses on interpersonal skills essential for collaboration and relationship building. Effective communication, empathetic interactions, and the ability to work cohesively within teams are crucial for fostering positive dynamics in client and team engagements.</li>
            </ul>
            <h3>THINK: How people think about the world around them and the tasks they focus on</h3>
            <ul>
                <li><b>Attributes:</b> Problem Solving, Critical Thinking</li>
                <li><b>Summary:</b> "Think" encapsulates the cognitive traits that drive analytical and innovative approaches. Problem-solving and critical thinking skills allow individuals to navigate challenges thoughtfully, while a growth mindset ensures that they are open to learning and adapting their perspectives.</li>
            </ul>
            <p>
                These attributes together form a comprehensive skill set that is
                highly valued in the digital space, enabling professionals to thrive
                in diverse roles and adapt to the dynamic nature of the industry.
            </p>

            <ul>
                <li><b>Adaptability & Resilience:</b> Adaptability and resilience are essential for navigating the fast-paced changes in digital landscapes and technologies. This attribute reflects an individual's ability to adjust their approach and thrive amid evolving challenges, from new software rollouts to shifting project demands. It is critical because digital roles often involve rapid advancements in technology, and being open to change enables individuals to remain relevant and successful in their careers.</li>
                <li><b>Problem Solving:</b> Problem-solving skills involve the ability to identify issues, understand root causes, and create innovative solutions. In digital roles, where challenges can range from technical glitches to project bottlenecks, problem-solving ensures that issues are managed efficiently. This skill is essential as it helps maintain productivity and supports effective digital transformations by allowing individuals to address obstacles creatively and strategically.</li>
                <li><b>Communication Skills:</b> Effective communication skills, both verbal and written, are essential for articulating complex technical concepts in a way that’s understandable to all stakeholders. Adaptability in communication style and the ability to resolve conflicts are equally important, especially in diverse teams and client-facing roles. In a digital context, strong communication fosters collaboration, ensures alignment, and builds trust, ultimately enhancing project outcomes and client satisfaction.</li>
                <li><b>Time Management:</b> In the digital world, managing multiple priorities is crucial as projects often involve various stakeholders, timelines, and deliverables. Effective time management skills enable professionals to prioritise tasks, meet deadlines, and reduce stress. This is essential in digital roles where productivity, efficiency, and meeting client expectations are key metrics of success.</li>
                <li><b>Curiosity:</b> Curiosity drives a proactive approach to learning and exploration, leading individuals to investigate new technologies, methods, and ideas. This natural inquisitiveness is vital in digital roles where staying updated with emerging trends and innovations is essential. Curiosity fosters a mindset that values continual improvement, making it easier for teams to innovate and adapt to shifts in the digital landscape.</li>
                <li><b>Attention to Detail:</b> Attention to detail ensures that digital professionals can identify nuances, catch errors, and fully understand the scope of a project. This attribute is critical in a field where precision is key—whether it's writing clean code, interpreting data, or designing a user interface. It underpins quality control and contributes to the delivery of high-standard outcomes, minimising risks associated with oversight.</li>
                <li><b>Growth Mindset:</b> A growth mindset signifies a commitment to personal and professional development, especially in the face of challenges. This mindset supports individuals in digital fields where upskilling and adapting to new tools and methodologies are frequent. By fostering a culture of continuous improvement, a growth mindset drives long-term career success and keeps professionals adaptable and competitive in the job market.</li>
                <li><b>Critical Thinking:</b> Critical thinking encompasses the ability to analyse information, identify patterns, and challenge assumptions. In the digital space, this skill is invaluable for making data-driven decisions, solving complex problems, and optimising processes. Critical thinkers can sift through information objectively, enabling them to make sound judgments and contribute to informed strategy and innovation within their teams.</li>
                <li><b>Team Collaboration:</b> Team collaboration is the ability to work effectively with colleagues from diverse backgrounds and expertise. In digital projects, which often involve cross-functional teams, this skill ensures that workflows are seamless, ideas are shared, and goals are achieved. Effective collaboration builds a supportive culture that leverages the collective strengths of a team, ultimately driving project success.</li>
                <li><b>Empathy:</b> Empathy is the capacity to understand and consider others’ perspectives, a key attribute for fostering inclusive, user-centred design and strong client relations. In digital environments, where solutions are often created to meet user needs, empathy enables professionals to create meaningful, accessible experiences. It also strengthens interpersonal relationships, leading to better teamwork and client satisfaction.</li>
            </ul>

            <h3>Sources</h3>
            <p>
            As well as being developed in collaboration with employers and learners in the field, there are a number of sources informing the relevance of the attributes and the alignment of those attributes to roles;
            </p>
            <SourcesParagraph>
              International Institute of Business Analysis (IIBA), Project Management Institute (PMI), Gartner, Randstad, Tech, SFIA, Certified Information Systems Auditor (CISA), Certified Information Security Manager (CISM), HRCI, U.S. Bureau of Labor Statistics (BLS), UK's National Careers Service, Canadian Information Processing Society (CIPS), Harvard Business School, Stanford Graduate School of Business, Massachusetts Institute of Technology (MIT), LinkedIn, Indeed, Glassdoor, Gemini, Hyperloop, Reed, e2e, GM Police, Data Management Body of Knowledge (DMBOK), ITIL Framework, CompTIA (Technical certifications)
            </SourcesParagraph>
        </>
      )}
    </ModalTemplate>
  );
};

export default AttributeInfo;

const SourcesList = styled.ul`
    font-size: 12px;
`;

const SourcesParagraph = styled.p`
    font-size: 12px !important;
`;