import styled from "styled-components"
import NavBar from "./NavBar.js"
import Footer from "./Footer.js"
import BlurryBackground from "./BlurryBackground.js"
import { useUser } from "../context/UserContext.js"
import Error from "./Modals/Error.js"
import Contact from "./Modals/Contact.js"

const Layout = ({children}) => {
    const { showErrorModal, setShowErrorModal, showContactForm, setShowContactForm} = useUser();

    return (
        <>
            <BlurryBackground/>
            <NavBar />
            <PageWrap>
                <CenterSection>
                    {children}
                </CenterSection>
            </PageWrap>
            <Footer/>
            <Error showModal={showErrorModal} setShowModal={setShowErrorModal} />
            <Contact showModal={showContactForm} setShowModal={setShowContactForm} />
        </>
    )
}

export default Layout

const PageWrap = styled.div`
    box-sizing:border-box;
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 71px);
`;

const CenterSection = styled.div`
    width: 100%;
`;