import { Link, useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import arrowDown from "../assets/images/arrow-down.png";
import Cookies from "universal-cookie";
import longCNLogo from "../assets/images/longWhiteCNLogo.png";
import { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import digiDnaLogo from "../assets/images/digiDNALogo.png";
import { device } from "../styles/Responsive.style.js";
import { useUser } from "../context/UserContext.js";

const cookies = new Cookies();

const NavBar = () => {
    const [loggedIn, setLoggedInUser] = useState({name: undefined, role: null});
    const [hamburgerOpen, setHamburgerOpen] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const { setShowContactForm } = useUser();

    useEffect(() => {
        const token = cookies.get("token");
        if(token !== undefined){
            const decoded = jwtDecode(token);
            setLoggedInUser({name: decoded.name, role: decoded.role})
        }
    }, [])

    const logOut = async () => {
        await cookies.remove("token");
        setHamburgerOpen(false);
        if(location.pathname === "/"){
            setLoggedInUser({name: undefined, role: null});
        }
        navigate("/")
    }

    return (
        <Nav>
            <LeftSection>
                <div className="fullTitle">
                    <Link to="/"><img src={digiDnaLogo} alt="Logo"/></Link>
                    <h1><Link to="/"><b><span>digi</span>DNA</b></Link> powered by <a href="https://wearecodenation.com/" target="_blank" rel="noreferrer"><img src={longCNLogo} alt="Code Nation Logo"/></a></h1>
                </div>

                <Link to="/" className="mobileLeft">
                    <img src={digiDnaLogo} alt="Logo"/>
                    <h1><b><span>digi</span>DNA</b></h1>
                </Link>
            </LeftSection>


            {loggedIn.name === undefined ? (
                <RightSection>
                    <ContactBtn onClick={() => setShowContactForm(true)} className="btnStyle">Get In Touch</ContactBtn>
                    <ButtonLink className="btnStyle" to="/login">Log In</ButtonLink>
                </RightSection>
            ) : (
                <RightSection className="loggedIn">
                    <div className="full">
                        <Link className="navBtn" to="/questionnaires">Questionnaires</Link>
                        <DropDownWrapper>
                            <p className="navBtn">{loggedIn.name === null ? "Profile" : loggedIn.name }<img src={arrowDown} alt="arrow" /></p>
                            <DropDownLinks>
                                {loggedIn.role === "Admin" ? (
                                    <DropDownLink to="/adminDash">Dashboard</DropDownLink>
                                ) : loggedIn.role === "SuperAdmin" ? (
                                    <DropDownLink to="/supAdminDash">Dashboard</DropDownLink>
                                ) : null}
                                <DropDownBtn onClick={logOut}>Log Out</DropDownBtn>
                            </DropDownLinks>
                        </DropDownWrapper>
                    </div>
                    <Hamburger className="hamburger">
                        <button className={hamburgerOpen ? "closeBtn" : ""} onClick={() => setHamburgerOpen(!hamburgerOpen)}>
                            <div className="hamburgerBtn"></div>
                        </button>
                        <HamburgerDropdown className={hamburgerOpen ? "open" : "closed"}>
                            <Link to="/">Home</Link>
                            <Link to="/questionnaires">Questionnaires</Link>
                            {loggedIn.role === "Admin" ? (
                                <Link to="/adminDash">Dashboard</Link>
                            ) : loggedIn.role === "SuperAdmin" ? (
                                <Link to="/supAdminDash">Dashboard</Link>
                            ) : null}
                            <button className="logOutBtn" onClick={logOut}>Log Out</button>
                        </HamburgerDropdown>
                    </Hamburger>
                </RightSection>
            )}
        </Nav>
    )
}

export default NavBar;

const Nav = styled.nav`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 55px;
    background-color: var(--dark-bg);

    .loggedIn .full {
        gap: 0 0px;
    }

    @media ${device.medium} {
        padding: 0 20px;
    }
`;

const LeftSection = styled.div`

    & > div {
        display: flex;
        align-items: center;
    }

    a {
        display: flex;
        align-items: center;
        text-decoration: none;
    }

    img {
        margin-right: 10px;
    }

    h1 {
        font-weight: 400;
        margin: 15px 0;
        color: var(--text-light);
        display: flex;
        align-items: center;
        font-size: 18px;
        text-align:center;
    }

    b {
        text-decoration: none;
        font-size: 28px;
        color: var(--text-light);
        margin-right: 5px;
    }

    span {
        color: var(--text-light);
        font-weight: 400;
    }

    img {
        height: 28px;
        margin-left: 5px;
    }
    

    .mobileLeft {
        display: none;
    }

    @media ${device.large} {
        .fullTitle {
            display: none;
        }
        .mobileLeft {
            display: flex;
        }
    }

    @media ${device.small} {
        margin: 10px 0;
        h1 {
            display: none;
        }
        img {
            height: 35px;
        }
    }
`;

const RightSection = styled.div`
    .full {
        display: flex;
        gap: 0 38px;
    }

    .navBtn {
        color: var(--text-light);
        padding: 0px 15px;
        transition: background-color 0.1s;
        height: 71px;
        border-radius: 0px;
        display: flex;
        align-items: center;
        font-weight: 900;
        text-decoration: none;
        font-size: 18px;
    }
    .navBtn:hover {
        background-color: var(--dark-bg-hover);
    }

    .btnStyle {
        font-weight: 900;
        font-size: 20px;
        text-decoration: none;
        padding: 10px 35px;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        color: var(--text-light);
    }

    .hamburger {
        display: none;
    }

    @media ${device.medium} {
        .btnStyle {
            font-size: 16px;
            padding: 10px 15px;
        }
    }

    @media ${device.small} {
        .full {
            display: none;
        }

        .hamburger {
            display: block;
        }
    }
`;

const ContactBtn = styled.button`
    background-color: var(--dark-bg-hover);
    margin-right: 20px;

    &:hover {
        background-color: var(--gray-dark);
    }

    @media ${device.small} {
        margin-right: 10px;
    }
`;

const ButtonLink = styled(Link)`  
    background-color: var(--accent);

    &:hover {
        background-color: var(--accent-hover);
    }
`;

const DropDownWrapper = styled.div`
    display: inline-block;
    position: relative;
    z-index: 4;

    p {
        color: var(--text-light);
        padding: 0px 35px;
        margin: 0;
        height: 71px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    p:hover {
        background-color: var(--dark-bg-hover);
    }

    img {
        height: 20px;
        width: 20px;
        margin-left: 10px;
        filter: invert(92.5%);
    }

    &:hover > div {
        top: 100%;
        left: 0px;
    }
`;

const DropDownLinks = styled.div`
    background-color: var(--primary-gray);
    position: absolute;
    top: -300px;
    width: 100%;
    box-shadow: 1px 4px 7px rgba(113, 113, 113, 0.5);
`;

const DropDownLink = styled(Link)`
    padding: 15px 20px;
    display: block;
    text-decoration: none;
    font-weight: 900;
    font-size: 18px;

    &:hover {
        background-color: var(--secondary-gray);
    }
`;

const DropDownBtn = styled.button`
    padding: 15px 20px;
    display: block;
    text-decoration: none;
    font-weight: 900;
    font-size: 18px;
    border: none;
    border-radius: 0px;
    width: 100%;
    text-align: left;
    cursor: pointer;

    &:hover {
        background-color: var(--secondary-gray);
    }
`;

const Hamburger = styled.div`
    height: 71px;

    & > button {
        height: 100%;
        width: 55px;
        border: none;
        cursor: pointer;
        background: none;
        position: relative;
        z-index: 6;
        top: 0px;
        left: 0px;
        transition: all 0.3s;
    }    

    .closeBtn {
        top: 30px;
        left: -25px;
    }

    .hamburgerBtn {
        height: 5px;
        width: 100%;
        border-radius: 5px;
        background-color: white;
        position: relative;
        transition: all 0.3s;

        &::before, &::after {
            position: absolute;
            content: '';
            height: 5px;
            width: 100%;
            border-radius: 5px;
            background-color: white;
            transition: all 0.3s;
        }

        &::before {
            top: -13px;
            left: 0;
        }

        &::after {
            top: 13px;
            left: 0;
        }
    }

    .open {
        top: 0;
    }

    .closeBtn .hamburgerBtn {
        transform: rotate(180deg);
        background-color: rgba(0, 0, 0, 0);        
    }

    .closeBtn .hamburgerBtn::before {
        transform: rotate(45deg);
        width: 55px;
        top: 0px;
        left: -6px;
    }

    .closeBtn .hamburgerBtn::after {
        transform: rotate(135deg);
        width: 55px;
        top: 0px;
        left: -6px;
    }

    .closed {
        top: -100vh;
    }
`;

const HamburgerDropdown = styled.div`
    position: fixed;
    z-index: 5;
    right: 0;
    left: 0;
    top: 0;
    background-color: var(--dark-bg);
    height: 100vh;
    padding: 110px 55px 0 55px;
    box-sizing: border-box;
    transition: top 0.3s;

    a {
        padding: 15px 20px;
        display: block;
        text-decoration: none;
        font-weight: 900;
        font-size: 36px;
        color: white;
        width: 100%;
    }

    .logOutBtn {
        padding: 15px 20px;
        display: block;
        text-decoration: none;
        font-weight: 900;
        font-size: 36px;
        color: var(--accent);
        width: 100% - 100px;
        border: none;
        cursor: pointer;
        background: none;
        text-align: left;
    }
`;
