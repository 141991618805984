import { useEffect, useRef, useState } from "react";
import { getUsers } from "../utils/index.js";
import PageTransition from "../components/PageTransition.js";
import Success from "../components/Modals/Success.js";
import NoInvites from "../components/Modals/NoInvites.js";
import styled from "styled-components";
import AdminViewResults from "../components/Modals/AdminViewResults.js";
import SpiderChart from "../components/SpiderChart.js";
import { device } from "../styles/Responsive.style.js";
import { Dropdown, CheckboxDropdownWrapper, CheckboxDropdownBtn, CheckboxDropdownOptions } from "../styles/Form.styles.js";
import SpiderChartReport from "../components/SpiderChartReport.js";
import TableSection from "../components/Table/TableSection.js";
import { useAdmin } from "../context/AdminContext.js";
import GroupSection from "../components/Groups/GroupSection.js";
import { calculateResults } from "../utils/downloads.js";


const AdminDash = () => {
    const {adminData, setAdminData, allUserData, setAllUserData, displayUsers, setDisplayUsers, setCurrentPage, noInviteModal, setNoInviteModal, selectedUser, successModal, setSuccessModal, viewResultsModal, setViewResultsModal, allQuizzes, setAllQuizzes, selectedQuizId, setSelectedQuizId, generateSpiders, generateSpidersForSelected, selectedUsers, compareUsers, setCompareUsers, spiderData, setSpiderData } = useAdmin();

    const [isLoading, setIsLoading] = useState(true);

    const [dropddownOpen, setDropdownOpen] = useState(false);
    const dropdown = useRef()

    const [spiderComparisonData, setSpiderComparisonData] = useState([]); 
    const [scoreMinMax, setScoreMinMax] = useState({min: 0, max: 0})   

    const handleInitialFetch = async () => {
        let data = await getUsers();

        // use Admin Context
        setAdminData(data.adminInfo);
        setAllUserData(data.userData);
        setDisplayUsers(data.userData);
        setCurrentPage(1);
        setAllQuizzes(data.quizInfo);
        setSelectedQuizId(data.quizInfo[0]._id);
        
        loadQuizCharts(data.quizInfo[0]._id, data.quizInfo, data.userData)
        setIsLoading(false);
    }

    const handleSelect = (id) => {
        if(compareUsers.findIndex((el) => el === id) === -1){
            setCompareUsers([...compareUsers, id])
        } else {
            setCompareUsers(compareUsers.filter(el => el !== id))
        }
    }

    useEffect(() => {
        setTimeout(() => {
            handleInitialFetch()
        }, 500);
    }, [])

    useEffect(() => {
        const closeDropdown = (e) => {
          if(dropdown.current && dropddownOpen && !dropdown.current.contains(e.target)){
            setDropdownOpen(false)
          }
        }
        document.addEventListener("mousedown", closeDropdown)
        return () => {
          document.removeEventListener("mousedown", closeDropdown)
        }
    })

    const loadQuizCharts = (quizId, quizzes, users) => {
        setSelectedQuizId(quizId);
        setCompareUsers([]);
        let selectedQuiz = quizzes.find(q => q._id === quizId)
        let allCompleteResults = users.map(user => user.results.find(q => q.quizId === quizId && q.date_completed !== null)).filter(q => q !== undefined)

        setScoreMinMax({
            min: selectedQuiz.questions.reduce((p,c) => {
                let highestForQusetion = c.answers.reduce((q, d) => d.points < q ? d.points : q, 0) 
                return highestForQusetion < p ? highestForQusetion : p
            }, 0),
            max: selectedQuiz.questions.reduce((p,c) => {
                let highestForQusetion = c.answers.reduce((q, d) => d.points > q ? d.points : q, 0) 
                return highestForQusetion > p ? highestForQusetion : p
            }, 0)
        })

        let attributes = [...new Set(selectedQuiz.questions.map(q => q.attribute))]
        let scores = attributes.map((el) => [])

        allCompleteResults.forEach((results) => {
            results.answers.forEach((answer) => {
                let questionObj = selectedQuiz.questions.find(q => q._id === answer.question_id);
                let chosenAnsObj = questionObj.answers.find(ans => ans._id === answer.answer_id);
                scores[attributes.indexOf(questionObj.attribute)].push(chosenAnsObj.points);
            })
        })

        setSpiderData({ title: `${selectedQuiz.title} Average`, attribute: attributes.map((att,i) => {if(i === 0 || i ===5) return att;let a = att.split("& "); if(a.length > 1)a[0] += " &"; return a}), avgScores: scores.map((att) => (att.reduce(( p, c ) => p + c, 0 ) / att.length).toFixed(1))})
        setSpiderComparisonData({ attribute: attributes.map((att,i) => {if(i === 0 || i ===5) return att;let a = att.split("& "); if(a.length > 1)a[0] += " &"; return a}), dataSets: [{name: `${selectedQuiz.title} Average`, data: scores.map((att) => (att.reduce(( p, c ) => p + c, 0 ) / att.length).toFixed(1))}]})
    }

    useEffect(() => {
        if(allQuizzes.length === 0) return
        let selectedQuiz = allQuizzes.find(q => q._id === selectedQuizId)
        let attributes = [...new Set(selectedQuiz.questions.map(q => q.attribute))]
        let dataSets = compareUsers.map(i => {
            let scores = attributes.map((el) => [])
            let userObj = allUserData.find((u) => u._id === i)
            userObj.results.find(r => r.quizId === selectedQuizId).answers.forEach((answer) => {
                let questionObj = selectedQuiz.questions.find(q => q._id === answer.question_id);
                let chosenAnsObj = questionObj.answers.find(ans => ans._id === answer.answer_id);
                scores[attributes.indexOf(questionObj.attribute)].push(chosenAnsObj.points);
            })
            return {data: scores.map((att) => att.reduce(( p, c ) => p + c, 0 ) / att.length), name: userObj.name}
        })
        dataSets.unshift({data: spiderData.avgScores, name: `${selectedQuiz.title} Average`})
        setSpiderComparisonData({attribute: attributes.map((att,i) => {if(i === 0 || i ===5) return att;let a = att.split("& "); if(a.length > 1)a[0] += " &"; return a}), dataSets: dataSets})
    }, [compareUsers])

    return (
        <PageWrapper>
            <Screen className={dropddownOpen ? "open-screen" : ""} />

            <DashboardWrapper>
                <h1>{adminData.company} Dashboard</h1>

                
                <AveragesSection>
                    <div>
                        <Dropdown className='dropdown'>
                            <select onChange={(e) => loadQuizCharts(e.target.value, allQuizzes, allUserData)}>
                                {allQuizzes.map((quiz, index) => (
                                    <option key={index} value={quiz._id}>{quiz.title}</option>
                                ))}
                            </select>
                        </Dropdown>

                        {!isLoading && (
                            <div className="radarWrapper">
                                <SpiderChart categories={spiderData.attribute} pointRange={scoreMinMax} series={[{name: allQuizzes.find(q => q._id === selectedQuizId).title, data: spiderData.avgScores}]} title={`${allQuizzes.find(q => q._id === selectedQuizId).title} Average Scores`} setSummaryData={false} forDoc={false}/>
                            </div>
                        )}
                    </div>
                    
                    <div>
                        <CheckboxDropdownWrapper className={dropddownOpen ? "dropdown-open" : ""} ref={dropdown}>
                            <CheckboxDropdownBtn onClick={() => setDropdownOpen(!dropddownOpen)} className={dropddownOpen ? "btnOpen" : "btnClose"}>Select Users</CheckboxDropdownBtn>
                            <CheckboxDropdownOptions className={dropddownOpen ? "open" : "closed"}>
                            {displayUsers.filter((u) => u.results.findIndex((q) => q.quizId === selectedQuizId && q.date_completed !== null) !== -1).map((user, index) => {
                                return (
                                <label key={index} className={compareUsers.findIndex((el) => el === user._id) === -1 ? "" : "optionSelected"}>
                                    <input type="checkbox" onClick={() => handleSelect(user._id)} />
                                    {user.name}
                                </label>
                                )
                            })}
                            </CheckboxDropdownOptions>
                        </CheckboxDropdownWrapper>

                        {!isLoading && (
                            <div className="radarWrapper">
                                <SpiderChart categories={spiderComparisonData.attribute} pointRange={scoreMinMax} series={spiderComparisonData.dataSets} title={`Selected Users against Average`} setSummaryData={false} forDoc={false}/>
                            </div>
                        )}
                    </div>
                </AveragesSection>

                {generateSpiders && ( <SpiderChartReport users={calculateResults(adminData, allQuizzes.find(q => q._id === selectedQuizId), displayUsers)} range={scoreMinMax} />)}
                {generateSpidersForSelected && ( <SpiderChartReport users={calculateResults(adminData, allQuizzes.find(q => q._id === selectedQuizId), selectedUsers.map((u) => allUserData.find(uObj => uObj._id === u)))} range={scoreMinMax} />)}

                <GroupSection />

                <TableSection />
                
                <AdminViewResults showModal={viewResultsModal} setShowModal={setViewResultsModal} userInfo={selectedUser} />
                <NoInvites showModal={noInviteModal} setShowModal={setNoInviteModal} />
                <Success showModal={successModal} setShowModal={setSuccessModal} who="Users"/>
                <PageTransition isLoading={isLoading} />
            </DashboardWrapper>
        </PageWrapper>
    )
}

export default AdminDash;

const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 25px;
`;

const DashboardWrapper = styled.main`
    width: 100%;
    max-width: 1500px;

    .downloadPdfWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .downloadPdf {
        border: 3px solid var(--accent);
        border-radius: 5px;
        background-color: var(--accent);
        font-size: 13px;
        font-weight: 900;
        color: white;
        cursor: pointer;
        letter-spacing: 1px;
        box-shadow: 0 0 0 0px white inset;
        transition: all 0.2s;
        text-decoration: none;
        padding: 5px 15px;
        margin: 0 10px 0 0;

        &:hover {
            box-shadow: 0 0 0 2px white inset;
            background-color: var(--accent-hover);
        }
    }

    @media ${device.small} {
        .downloadPdfWrapper {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }

        .downloadPdf {
            display: block;
            width: 150px;
            margin: 5px 0; 
        }
    }
`;

const AveragesSection = styled.header`
    display: flex;

    & > div {
        width: 50%;
    }

    .dropdown {
        width: 355px;
    }

    .radarWrapper {
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }

    @media ${device.medium} {
        flex-direction: column;
        .dropdown {
            width: 100%;
        }

        & > div {
            width: 100%;
        }
    }
`;

const Screen = styled.div`
    position: fixed;
    height: 0;
    width: 100vw;
    bottom: 0px;
    left: 0px;
    z-index: 15;

    &.open-screen {
        height: 100vh;
    }
`;