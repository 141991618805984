import { useAdmin } from "../../../context/AdminContext.js";
import { useUser } from "../../../context/UserContext.js";
import { GraySecondaryBtn, RedPrimaryBtn } from "../../../styles/Button.styles.js";
import { deleteGroup } from "../../../utils/index.js";
import ModalTemplate from "../ModalTemplate.js";
import styled from "styled-components";


const DeleteGroup = ({ showModal, setShowModal, group_id, setDeleteSuccessModal }) => {

    const { setBlurryBg, setShowErrorModal } = useUser();
    const { adminData } = useAdmin();

    const handleDeleteGroup = async () => {
        const res = await deleteGroup(group_id)

        if(res.message === "Successfully deleted users and group") {
            setShowModal(false);
            setDeleteSuccessModal(true);
            
        } else {
            setShowErrorModal(true);
            setShowModal(false);
        }
    }

    const cancelBtn = () => {
        setShowModal(false);
        setBlurryBg(false);
    }

    return (
        <ModalTemplate showModal={showModal} setShowModal={setShowModal}>
            <h2>Are you sure?</h2>
            <p>Are you sure you want to delete this group? This is a irreversible action and will delete the accounts of all the users within this group.</p>
            <p><RedBold>{adminData.groups.find(obj => obj._id === group_id).users.length} user accounts will be deleted</RedBold> if you delete this group. This means the results and access to DigiDNA for those individuals will be removed.</p>
            <p>You <RedBold>will not get back the invites</RedBold> you have already used.</p>
            <p>If you want to continue and <RedBold>delete the Staff Group</RedBold> then click the red button below.</p>
            <Center>
                <GraySecondaryBtn onClick={cancelBtn}>Cancel</GraySecondaryBtn>
                <RedPrimaryBtn onClick={() => handleDeleteGroup()} $text="Delete Users"></RedPrimaryBtn>
            </Center>
        </ModalTemplate>
    );
};

export default DeleteGroup;

const RedBold = styled.b`
    color: var(--red);
`;

const Center = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 40px;
    gap: 20px;
`;