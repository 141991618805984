import styled from "styled-components"
import { PrimaryButton, SecondaryButton } from "../styles/Button.styles.js";
import { Link } from "react-router-dom";
import { device } from "../styles/Responsive.style.js";


const QuizCard = ({ status, quizInfo }) => {

    return (
        <CardWrapper>
            <LeftImg>
                <img src={quizInfo.image} alt="dna strand"/>
            </LeftImg>
            <div className="rightSection">
                <Content>
                    <h1>{quizInfo.info.title}</h1>
                    <p>{quizInfo.info.description}</p>
                </Content>
                <RightBtn>
                    {(status === "completed") ? (
                        <Link to={`/results/${quizInfo.info._id}`}>
                            <SecondaryButton>View Results</SecondaryButton>
                        </Link>
                    ) : (status === "doing") ? (
                        <Link to={`${quizInfo.info._id}/${quizInfo.info.title.split(" ").join("-")}`}>
                            <PrimaryButton>Continue</PrimaryButton>
                        </Link>
                    ) : (
                        <Link to={`${quizInfo.info._id}/${quizInfo.info.title.split(" ").join("-")}`}>
                            <PrimaryButton>Start</PrimaryButton>
                        </Link>
                    ) }
                </RightBtn>
            </div>
        </CardWrapper>
    )
}

export default QuizCard;

const CardWrapper = styled.section`
    padding: 1px;
    background-color: var(--primary-gray);
    margin: 25px 25px 0 25px;
    height: 230px;
    border-radius: 15px;
    border: 5px solid var(--primary-gray);
    box-shadow: 0 0 0 1px var(--secondary-gray) inset;
    display: flex;
    max-width: 1500px;

    .rightSection {
        display: flex;
    }

    @media ${device.large} {
        height: 100%;
        .rightSection {
            flex-direction: column;
        }
    }

    @media ${device.small} {
        margin: 25px 0 0 0;
        height: auto;
        flex-direction: column;

        .rightSection {
            display: block;
        }
    }
`;

const LeftImg = styled.aside`
    height: 100%;
    border-radius: 9.5px 0 0 9.5px;
    overflow: hidden;
    flex: 0 0 350px;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    @media ${device.large} {
        height: auto;
        flex: 0 0 250px;
    }

    @media ${device.medium} {
        flex: auto;
    }
    @media ${device.small} {
        flex: 0 0 150px;
        border-radius: 9.5px 9.5px 0 0;
    }
`;

const Content = styled.article`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1 auto;
    padding: 15px 30px;
    overflow: hidden;

    h1 {
        font-size: 30px;
        margin: 0;
    }

    p {
        font-size: 16px;
        margin: 0;
    }

    @media ${device.medium} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 15px 20px;
        h1 {
            font-size: 28px;
            margin: 0;
        }

        p {
            font-size: 16px;
            margin: 0;
        }
    }
`;

const RightBtn = styled.aside`
    display: flex;
    align-items: center;
    flex: 0 0 229px;
    margin: 0 0 15px 15px;

    @media ${device.large} {
        flex: auto;
    }

    @media ${device.small} {
        flex: 0 0 150px;
        margin: 0 0 20px 20px;
    }
`;