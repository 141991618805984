import Tick from "../../assets/icons/Tick.js";
import { useUser } from "../../context/UserContext.js";
import ModalTemplate from "./ModalTemplate.js";

import styled from "styled-components";

const Success = ({ showModal, setShowModal, who }) => {

    const {setBlurryBg} = useUser();

    const closeModal = () => {
        setShowModal(false);
        setBlurryBg(false);
    }

    return (
        <ModalTemplate showModal={showModal} setShowModal={setShowModal}>
            <ContentWrapper>
                <Tick strokeCol="#11D600" width="100px"/>
                <h1>Success!</h1>
                <p>{who} have been successfully added and they will recieve an email instructing them how to sign in.</p>
                <button onClick={closeModal}>Ok</button>
            </ContentWrapper>
        </ModalTemplate>
    )
}

export default Success;

const ContentWrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    
    h1, p, button {
        width: 280px;
    }
    h1 {
        font-size: 40px;
        margin: 10px;
        text-align: center;
    }
    p {
        margin: 0;
        text-align: center;
    }
    button {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        margin-top: 10px;
        cursor: pointer;
        background-color: var(--primary-gray);

        &:hover {
            background-color: var(--secondary-gray);
        }
    }
`;