import { useState, useRef } from "react";
import styled from "styled-components";
import EditGroup from "../Modals/Groups/EditGroup";
import EditGroupSuccess from "../Modals/Groups/EditGroupSuccess";
import DeleteGroup from "../Modals/Groups/DeleteGroup";
import DeleteGroupSuccess from "../Modals/Groups/DeleteGroupSuccess";

const Kebab = ({ group_id }) => {
    const [openKebab, setOpenKebab] = useState(false);
    const backgroundRef = useRef();
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [openDeleteSuccessModal, setDeleteSuccessModal] = useState(false);
    const [openEditModal, setEditModal] = useState(false);
    const [openEditSuccessModal, setEditSuccessModal] = useState(false);

    const toggleKebab = () => {
        setOpenKebab(!openKebab)
    }

    return (
        <>
            <div onClick={toggleKebab}>
                <KebabWrapper className={openKebab ? "dropdown-open" : ""}>
                    <KebabBtn>
                        <span></span>
                    </KebabBtn>
                    <KebabDropdown className={openKebab ? "open" : ""}>
                        <button onClick={() => setEditModal(true)}>Edit</button>
                        <button className="red" onClick={() => setDeleteModal(true)}>Delete</button>
                    </KebabDropdown>
                </KebabWrapper>
                <Screen ref={backgroundRef} className={openKebab ? "bg-open" : ""}></Screen>
            </div>
            <EditGroup showModal={openEditModal} setShowModal={setEditModal} setEditSuccessModal={setEditSuccessModal} group_id={group_id} />
            <EditGroupSuccess showModal={openEditSuccessModal} setShowModal={setEditSuccessModal} />
            <DeleteGroup showModal={openDeleteModal} setShowModal={setDeleteModal} group_id={group_id} setDeleteSuccessModal={setDeleteSuccessModal} />
            {openDeleteSuccessModal && <DeleteGroupSuccess showModal={openDeleteSuccessModal} setShowModal={setDeleteSuccessModal} group_id={group_id} /> }
        </>
    )
}

export default Kebab;

const KebabWrapper = styled.div`
    height: 23px;
    width: 23px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;

    &.dropdown-open{
        z-index: 15;
    }
    .open {
        display: flex;
    }
`;

const Screen = styled.div`
    position: fixed;
    height: 0vh;
    width: 100vw;
    top: 0px;
    left: 0px;

    &.bg-open {
        height: 100vh;
        z-index: 14;
    }
`;

const KebabBtn = styled.button`
    height: 100%;
    width: 100%;
    background-color: var(--gray-light);
    display: grid;
    place-items: center;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
    flex-shrink: 0;

    span {
        position: relative;
        height: 3px;
        width: 3px;
        background-color: var(--gray);
        border-radius: 100%;
        transition: background-color 0.2s;

        &:before, &:after {
            position: absolute;
            content: "";
            height: 100%;
            width: 100%;
            background-color: inherit;
            border-radius: 100%;
            left: 0;
        }

        &:before {
            top: -5px; 
        }
        &:after {
            top: 5px; 
        }
    }

    &:hover {
        background-color: var(--gray);
    }

    &:hover span {
        background-color: var(--gray-light)
    }
`;

const KebabDropdown = styled.div`
    width: 100px;
    display: none;
    flex-direction: column;
    box-shadow: 0 2px 10px 0 var(--gray-light);
    border-radius: 5px;
    position: relative;

    button {
        background-color: var(--bg-main);
        border: none;
        text-align: left;
        font-size: var(--font-small);
        padding: 5px 10px;
        cursor:pointer;
        transition: all 0.2s;

        &:first-child {
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
        }
        &:last-child {
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        &:not(.red):hover {
            background-color: var(--bg-main-light);
        }
    }

    .red {
        color: var(--red);
        background-color: var(--red-light);
        transition: all 0.2s;
    }
    .red:hover {
        color: var(--red-light);
        background-color: var(--red);
    }
`;