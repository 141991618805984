import styled from "styled-components";
import { device } from "../styles/Responsive.style";

const PrivacyNotice = () => {
    return (
        <PageWrapper>
            <h1>Welcome to our data privacy notice</h1>

            <h2>Aim</h2>
            <p>DigiDNA aims to ensure that all data collected from our community, including the end users of DigiDNA, is collected, stored and processed in accordance with the UK Data Protection Act and GDPR 2018.</p>
            <p>This applies to all data, regardless of whether it is in paper or digital format</p>

            <h2>Legislation and Guidance</h2>
            <p>This policy meets the requirements of the UK Data Protection Act and GDPR 2018, and is based on <a href="https://ico.org.uk/for-organisations/" rel="noreferrer" target="_blank">guidance published by the Information Commissioner’s Office</a>.</p>

            <h2>Definitions</h2>
            <table>
                <thead>
                    <tr>
                        <th>Term</th>
                        <th>Definition</th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Personal Data</td>
                    <td>Data from which a person can be identified, including data that, when combined with other readily available information, leads to a person being identified</td>
                </tr>
                <tr>
                    <td>Special Category Data</td>
                    <td>
                        <p>Data such as:</p>
                        <ul>
                            <li>Racial or ethnic origin</li>
                            <li>Political opinions</li>
                            <li>Religious beliefs, or beliefs of a similar nature</li>
                            <li>Where a person is a member of a trade union</li>
                            <li>Physical and mental health</li>
                            <li>Biometric and genetic data</li>
                            <li>Sexual orientation and sex life</li>
                            <li>Whether a person has committed, or is alleged to have committed, an offence</li>
                            <li>Criminal convictions</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>Processing</td>
                    <td>Obtaining, recording or holding dat</td>
                </tr>
                <tr>
                    <td>Data subject</td>
                    <td>The person whose Personal and Special Category Data is held or processed</td>
                </tr>
                <tr>
                    <td>Data Controller</td>
                    <td>A person, or organisation, that determines the purposes for which, and the manner in which, Persona and Special Category data is processed</td>
                </tr>
                <tr>
                    <td>Data Processor</td>
                    <td>A person, other than an employee of the Data Controller, who processes the data on behalf of the Data Control</td>
                </tr>
                </tbody>
            </table>

            <h2>Data Protection</h2>
            <h3>Principles</h3>
            <ul>
                <li>processed lawfully, fairly and in a transparent manner</li>
                <li>collected for specified, explicit and legitimate purposes</li>
                <li>adequate, relevant and limited to what is necessary</li>
                <li>accurate and, where necessary, kept up to date</li>
                <li>kept in a form which permits identification of data subjects for no longer than is necessary</li>
                <li>processed in a manner that ensures appropriate security of the personal data</li>
                <li>Accountability, integrity and confidentiality</li>
            </ul>
            <h3>Your Rights</h3>
            <ul>
                <li>Your right of access - You have the right to ask us for copies of your personal information</li>
                <li>Your right to rectification - You have the right to ask us to rectify personal information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete</li>
                <li>Your right to erasure - You have the right to ask us to erase your personal information in certain circumstances</li>
                <li>Your right to be informed – you have the right about how and why your data is used</li>
                <li>Your right to restriction of processing - You have the right to ask us to restrict the processing of your personal information in certain circumstances</li>
                <li>Your right to object to processing - You have the right to object to the processing of your personal information in certain circumstances</li>
                <li>Your right to data portability - You have the right to ask that we transfer the personal information you gave us to another organisation, or to you, in certain circumstances</li>
            </ul>
            <p>You are not required to pay any charge for exercising your rights. If you make a request, we have one month to respond to you.</p>
            <p>Please contact us at <a href="mailto:dpo@digidna.ai">dpo@digidna.ai</a> if you wish to make a request.</p>
        
            <h2>Roles and Responsibilities</h2>
            <p>The Directors have overall responsibility for ensuring that we comply with our obligations. Day-to-day responsibilities rest with the Head of Compliance. We will ensure that all staff are aware of their data protection obligations, and oversee any queries related to the storing or processing of Personal and Special Category Data. Our Head of Compliance is our nominated Data Protection Officer and can be contacted at <a href="mailto:dpo@digidna.ai">dpo@digidna.ai</a></p>
        
            <h2>Privacy/Fair Processing</h2>
            <p>We hold Personal Data about our clients and our end-users to support the functionality of DigiDNA. We do not share your data with anyone other than our clients who have commissioned DigiDNA to assist with their requirements.</p>
            <p>This data includes</p>
            <ul>
                <li>First Name</li>
                <li>Last Name</li>
                <li>any other data, you, as administrator deem necessary to collect whilst configuring your questionnaire</li>
            </ul>
            <p>We will only retain the data we collect for as long as is necessary to satisfy the purpose for which it has been collected.</p>

            <h2>Data Storage and Security</h2>
            <p>Paper based records, digital records and portable electronic devices, such as laptops and hard drives that contain personal information, are compliant with the UK Data Protection Act and GDPR 2018 and are regularly assessed by our DPO. Destruction and archiving of Personal Data procedures are also compliant.</p>

            <h2>How To Make a Complaint</h2>
            <p>If you feel that we have mishandled your Personal Data at any time you can make a complaint to <a href="mailto:dpo@digidna.ai">dpo@digidna.ai</a></p>

            <p>Alternatively, you can contact our DPO to make a complaint, <a href="mailto:dpo@digidna.ai">dpo@digidna.ai</a> or contact the Information Commissioners Office by visiting their website (<a href="" target="_blank" rel="noreferrer">https://ico.org.uk/make-a-complaint/</a>) or by calling their helpline on 0303 123 1113.</p>

            <PrivacyFooter>
                DigiDNA a Product of Code Nation<br/>Academy Court,<br/>94 Chancery Lane,<br/>London,<br/>WC2A 1DT.<br/>Company Number: 06903140.<br/>Registered in the UK.
            </PrivacyFooter>
        </PageWrapper>
    )
}

export default PrivacyNotice;

const PageWrapper = styled.main`
    width: 100%;
    max-width: 1500px;
    padding: 0 50px;
    box-sizing: border-box;
    margin: 0 auto;
    
    p {
        font-size: var(--font-small);
    }

    a {
        color: var(--blue-dark);
    }

    table {
        font-size: var(--font-small);
    }

    thead {
        background: var(--dark-bg);

        th {
            padding: 10px;
           color: var(--text-light); 
           text-align: left;
        }
    }
    tbody {
        

        tr:nth-child(even) {
            background: var(--primary-gray);
        }

        td {
            padding: 10px;
            vertical-align: top;

            p {
                margin: 0;
            }
        }
    }

    @media ${device.small} {
        padding: 0 10px;

        h1 {
            font-size: 24px;
        }

        h2 {
            font-size: 18px;
        }
    }
`;

const PrivacyFooter = styled.p`
    text-align: center;
    background: var(--gray-dark);
    color: var(--text-light);
    border-radius: 10px;
    padding: 20px 10px;
    margin: 30px 0;
`;