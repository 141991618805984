import styled from "styled-components";
import { FormButton } from "../styles/Button.styles.js";
import { FormInput } from "../styles/Form.styles.js";
import { useRef, useState } from "react";
import PageTransition from "../components/PageTransition.js";
import { requestResetPassword } from "../utils/index.js";

import { device } from "../styles/Responsive.style.js";

const ForgotPassword = () => {
    const [message, setMessage] = useState("");
    const emailRef = useRef();


    const handleRequest = async (e) => {
        e.preventDefault();

        const data = await requestResetPassword(emailRef.current.value)
        setMessage(data.message)
    }

    return (
        <>
            <SectionBlock>
                {message === "Reset email sent" ? (
                    <SuccessWrap>
                        <TickSymbol></TickSymbol>
                        <p>A password reset link has been sent to your account</p>
                    </SuccessWrap>
                ) : (
                    <RequestResetForm onSubmit={handleRequest}> 
                        <h1>Forgot Password</h1>
                        <FormInput>      
                            <input type="email" placeholder=" " ref={emailRef} required />
                            <label>Email</label>
                        </FormInput>
                        <Message>{message}</Message>
                        <FormButton type="submit">Send</FormButton>
                    </RequestResetForm>
                )}
                
            </SectionBlock>
            <PageTransition isLoading={false} />
        </>
    )
}

export default ForgotPassword;

const SectionBlock = styled.section`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${device.small} {
        align-items: flex-start;
    }
`;

const Message = styled.p`
    color: red;
    margin: 0;
    height: 19px;
    margin-top: 10px;
`;

const RequestResetForm = styled.form`
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    background-color: white;
    padding: 50px;
    border-radius: 5px;

    h1 {
        margin: 0;
        font-size: 48px;
    }

    button[type="submit"] {
        margin: 10px 0 0 0;
    }

    @media ${device.small} {
        width: 100%;
        padding: 20px;
        margin: 50px 5px 0;
    }
`;

const SuccessWrap = styled.div`
    display: grid;
    place-items: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    background-color: white;
    padding: 50px;
    border-radius: 5px;
    width: 230px;

    p {
        text-align: center;
        margin: 20px 0 0 0;
        font-size: 20px;
    }

    @media ${device.small} {
        margin: 50px 5px 0;
    }
`;

const TickSymbol = styled.div`
    height: 100px;
    width: 100px;
    border: 3px solid var(--green);
    border-radius: 50%;
    position: relative;

    &::before {
        position: absolute;
        content: "";
        height: 52px;
        width: 3px;
        top: calc(50% + 3px);
        left: calc(50% + 12px);
        transform: translate(-50%, -50%) rotate(45deg);
        border-radius: 5px;
        background-color: var(--green);
    }
    &::after {
        position: absolute;
        content: "";
        height: 28px;
        width: 3px;
        top: calc(50% + 12px);
        left: calc(50% - 15px);
        transform: translate(-50%, -50%) rotate(-45deg);
        border-radius: 5px;
        background-color: var(--green);
    }
`;