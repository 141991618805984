import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import GilroyBold from "../assets/fonts/Gilroy-ExtraBold.woff";
import GilroyLight from "../assets/fonts/Gilroy-Light.woff";
import topLogo from "../assets/images/pdfTopLogo.png";

Font.register({ family: 'Gilroy', fonts: [
    {src: GilroyBold, fontWeight: "bold"},
    {src: GilroyLight, fontWeight: "light"}
]  });

// Create styles
const styles = StyleSheet.create({
    main: {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingHorizontal: "25px",
        fontFamily: "Gilroy",
        fontWeight: "bold"
    },
    section: {
        width: "50%",
        paddingHorizontal: "20px"
    },
    header: {
        fontSize: 12,
        marginBottom: 10,
        justifyContent: "center",
        color: 'white',
        backgroundColor: "#222222",
        padding: "23px 20px 20px 20px",
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'black',
        fontFamily: "Gilroy",
        fontWeight: "light"
    },
    topLogo: {
        width: "300px",
    },
    text: {
        fontSize: 14
    },
    jobTitle: {
        fontSize: 10,
        margin: 0,
        color: 'black',
        fontWeight: "light"
    },
    image: {
        width: '85%'
    }
});

// Create Document Component
const ResultReport = ({ images }) => {
    return (
        <Document>
            <Page size="A4">
                <View style={styles.header} fixed>
                    <Image style={styles.topLogo} src={topLogo} />
                </View>
                <View style={styles.main}>
                    {images.map((person, i) => {
                        return (
                            <View key={i} style={styles.section}>
                                <Text style={styles.text}>{person.name}</Text>
                                {i !== 0 && (
                                    <View>
                                        <Text style={styles.jobTitle}>{person.jobs[0].job}</Text>
                                        <Text style={styles.jobTitle}>{person.jobs[1].job}</Text>
                                        <Text style={styles.jobTitle}>{person.jobs[2].job}</Text>  
                                    </View>
                                )}
                                <Image style={styles.image} src={person.image} />
                            </View>
                        )
                    })}
                </View>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>
        </Document>
    )
};

export default ResultReport;