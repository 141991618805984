import styled from "styled-components";

const Loader = ({ themeType }) => {
    return (
        <LoaderTheme>
            <LoaderWrapper className={(themeType === "light") ? "light" : "dark"}>
                {[...Array(15)].map((_, i) => (
                    <Strand key={i}>
                        <div className="loader-top-ball" style={{ animationDelay: `${i * -0.2}s`}}></div>
                        <div className="loader-bottom-ball" style={{ animationDelay: `${-1.5 - (i * 0.2)}s`}}></div>
                    </Strand>
                ))}
            </LoaderWrapper>
        </LoaderTheme>
    )
}

export default Loader;

const LoaderTheme = styled.div`
    .light {
        --front: white;
        --middle: #FFE7B5;
        --back: var(--light-accent-color);
    }

    .dark {
        --front: var(--accent);
        --middle: #FA8D18;
        --back: #FF6600;
    }
`;

const LoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Strand = styled.div`
    position: relative;
    width: 15px;
    height: 40px;
    opacity: 0;
    animation: 0.5s fadeIn forwards ease;
    animation-delay: 0.25s;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .loader-top-ball, .loader-bottom-ball {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 12px;
        width: 12px;
        border-radius: 100%;
        animation: 3s move infinite ease-in-out;
    }

    @keyframes move {
        0% {
            background-color: var(--middle);
            top: 100%;
            z-index: -3;
        }
        25% { 
            height: 8px;
            width: 8px;
            background-color: var(--back);
            z-index: -3;
        }
        50% {
            background-color: var(--middle);
            top: 0%;
            z-index: -3;
        }
        75% {
            height: 12px;
            width: 12px;
            background-color: var(--front);
            z-index: 3;
        }
        100% {
            top: 100%;
            background-color: var(--middle);
            z-index: 3;
        }
    }
`;