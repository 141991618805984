import { useEffect, useState } from "react";
import { PrimaryButton } from "../styles/Button.styles.js";
import PageTransition from "../components/PageTransition.js";
import AddAdmin from "../components/Modals/AddAdmin.js";
import styled from "styled-components";
import Success from "../components/Modals/Success.js";
import { getAllAdmins } from "../utils/index.js";
import EditAdmin from "../components/Modals/EditAdmin.js";
import SuccessInvites from "../components/Modals/SuccessInvites.js";
import SupAdminTable from "../components/Table/SupAdminTable.js";
import DeleteAdmin from "../components/Modals/DeleteAdmin.js";
import DeleteAdminSuccess from "../components/Modals/DeleteAdminSuccess.js";

const SupAdminDash = () => {
    const [showAddAdminModal, setShowAddAdminModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [successInviteModal, setSuccessInviteModal] = useState(false);
    const [editAdminModal, setEditAdminModal] = useState(false);
    const [deleteAdminModal, setDeleteAdminModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [openDeleteSuccessModal, setOpenDeleteSuccessModal] = useState(false)

    const [allAdmins, setAllAdmins] = useState([]);
    const [displayAllAdmins, setDisplayAllAdmins] = useState([]);
    const [selectedAdmin, setSelectedAdmin] = useState({});

    const initialFetch = async () => {
        const data = await getAllAdmins();
        setAllAdmins(data);
        setDisplayAllAdmins(data)
        setIsLoading(false)
    }

    useEffect(() => {
        initialFetch()
    }, []);

    const handleEditAdmin = (ad) => {
        setSelectedAdmin(ad);
        setEditAdminModal(true);
    }

    const handleDeleteAdmin = (ad) => {
        setSelectedAdmin(ad);
        setDeleteAdminModal(true);
    }

    return (
        <PageWrapper>
            <DashboardWrapper>
                <h1>digiDNA Dashboard</h1>

                <section>
                    <h2>Add Admin</h2>
                    <p>To add someone as an admin you can click the "Create an Admin" button below and follow the instructions. An admin will be able to complete the quizzes as well as invite others as basic users that can only do the quizzes. An admin will be able to see all of the results of the people they invite. The admin will also have a set amount of people they can invite which will be specified by you.</p>
                    <PrimaryButton onClick={() => setShowAddAdminModal(true)}>Add Admin</PrimaryButton>
                </section>

                <h2>All Admins</h2>

                <SupAdminTable allAdmins={allAdmins} displayAllAdmins={displayAllAdmins} setDisplayAllAdmins={setDisplayAllAdmins} handleEditAdmin={handleEditAdmin} handleDeleteAdmin={handleDeleteAdmin} />

                <DeleteAdminSuccess openDeleteSuccessModal={openDeleteSuccessModal} setOpenDeleteSuccessModal={setOpenDeleteSuccessModal} />
                <DeleteAdmin showModal={deleteAdminModal} setShowModal={setDeleteAdminModal} selectedAdmin={selectedAdmin} setOpenDeleteSuccessModal={setOpenDeleteSuccessModal} />
                <EditAdmin showModal={editAdminModal} setShowModal={setEditAdminModal} adminInfo={selectedAdmin} setAllAdmins={setAllAdmins} setSuccessInviteModal={setSuccessInviteModal} />
                <Success showModal={successModal} setShowModal={setSuccessModal} who="Admin"/>
                <SuccessInvites showModal={successInviteModal} setShowModal={setSuccessInviteModal} who={selectedAdmin.name} />
                <AddAdmin showModal={showAddAdminModal} setShowModal={setShowAddAdminModal} setAllAdmins={setAllAdmins} setSuccessModal={setSuccessModal} />
                <PageTransition isLoading={isLoading}/>
            </DashboardWrapper>
        </PageWrapper>
    )
}

export default SupAdminDash;

const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 25px;
`;

const DashboardWrapper = styled.main`
    width: 100%;
    max-width: 1500px;
    
    h2 {
        margin-top: 30px;
    }
`;