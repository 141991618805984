import PageTransition from "../components/PageTransition.js";
import QuizCard from "../components/QuizCard.js";
import styled from "styled-components";

import { device } from "../styles/Responsive.style.js";

import generalIcon from "../assets/images/generalQuestionnaire.jpeg";
import softSkills from "../assets/images/softSkills.jpg";
import cyberIcon from "../assets/images/cyberQuiz.jpeg";
import softwareIcon from "../assets/images/softwareQuiz.jpeg";
import dataIcon from "../assets/images/dataQuiz.webp";
import rpaIcon from "../assets/images/rpaQuiz.jpeg";
import aiIcon from "../assets/images/ai.jpeg";


import { useEffect, useState } from "react";
import { getAllQuizzes } from "../utils/index.js";

const Quizzes = () => {
    const [quizzes, setQuizzes] = useState([]);
    const [userData, setUserData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const handleInitialFetch = async () => {
        let data = await getAllQuizzes();
        setIsLoading(false);
        setQuizzes(data.res);
        setUserData(data.user.results)
    }

    useEffect(() => {
        handleInitialFetch()
    }, [])

    

    return (
        <QuizPage>
            <TextHeader>
                <h1>The Questionnaires</h1>
                <p>Embark on a journey of self-discovery with the Attribute Discovery Questionnaire. No matter your background, this interactive tool is designed to reveal the essential attributes for success in the vibrant field of digital technology. Engage with a variety of thought-provoking questions that reflect real-world scenarios, tailored for both novices and those with a digital background. 
                    <br/><br/>Encounter a variety of scenarios within the questionnaire that prompt reflection and analysis. Your responses will reveal your natural strengths and their alignment with the digital sector's demands. 
                    <br/><br/>Whether you are charting a new career path, refining existing skills, or exploring your fit in digital roles, this questionnaire is a step towards realising your potential. Embrace this opportunity to explore, discover, and step into the world of digital possibilities.
                </p>
                <p><b>This isn't about passing or failing</b>, it serves as a tool to prompt self-reflection, and a script to discuss your strengths and areas for improvement if you decide to make a move into the digital space.</p>
            </TextHeader>

            {quizzes.filter(q=> !q.archived).map((quiz, index) => {
                let matchQuiz = userData[userData.findIndex(completedQuizzes => completedQuizzes.quizId === quiz._id)]
                let status = (matchQuiz?.answers.length === 0) ? "remaining" : 
                             (matchQuiz?.date_completed === null) ? "doing":
                             (typeof matchQuiz?.date_completed === "string") ? "completed" : "remaining"
                return (
                    <QuizCard key={index} status={status} quizInfo={{info: quiz, image: generalIcon}} />
                )
            })}

            <CardWrapper>
                <LeftImg>
                    <img src={softSkills} alt="Soft Skills"/>
                </LeftImg>
                <Content>
                    <h2>Soft Skills</h2>
                    <h3>Coming Soon...</h3>
                </Content>
            </CardWrapper>
            <CardWrapper>
                <LeftImg>
                    <img src={cyberIcon} alt="Cyber Security"/>
                </LeftImg>
                <Content>
                    <h2>Cyber Security</h2>
                    <h3>Coming Soon...</h3>
                </Content>
            </CardWrapper>
            <CardWrapper>
                <LeftImg>
                    <img src={softwareIcon} alt="Software Developer"/>
                </LeftImg>
                <Content>
                    <h2>Software Developer</h2>
                    <h3>Coming Soon...</h3>
                </Content>
            </CardWrapper>
            <CardWrapper>
                <LeftImg>
                    <img src={dataIcon} alt="Data Analyst"/>
                </LeftImg>
                <Content>
                    <h2>Data Analyst</h2>
                    <h3>Coming Soon...</h3>
                </Content>
            </CardWrapper>
            <CardWrapper>
                <LeftImg>
                    <img src={rpaIcon} alt="RPA"/>
                </LeftImg>
                <Content>
                    <h2>Process Automation</h2>
                    <h3>Coming Soon...</h3>
                </Content>
            </CardWrapper>
            <CardWrapper>
                <LeftImg>
                    <img src={aiIcon} alt="AI"/>
                </LeftImg>
                <Content>
                    <h2>Prompt Engineering</h2>
                    <h3>Coming Soon...</h3>
                </Content>
            </CardWrapper>

            <PageTransition isLoading={isLoading} />
        </QuizPage>
    )
}

export default Quizzes;

const QuizPage = styled.main`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

const TextHeader = styled.div`
   padding: 0 25px; 
   max-width: 1500px;
`;

// for coming soon cards
const CardWrapper = styled.section`
    padding: 1px;
    background-color: var(--primary-gray);
    margin: 25px 25px 0 25px;
    height: 230px;
    border-radius: 15px;
    border: 5px solid var(--primary-gray);
    box-shadow: 0 0 0 1px var(--secondary-gray) inset;
    display: flex;
    width: calc(100% - 58px);
    max-width: 1500px;
    opacity: 0.5;

    @media ${device.small} {
        margin: 25px 0 0 0;
        height: 140px;
        width: calc(100% - 12px);
    }
`;

const LeftImg = styled.aside`
    height: 100%;
    border-radius: 9.5px 0 0 9.5px;
    overflow: hidden;
    width: 350px;
    flex: 0 0 350px;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    @media ${device.medium} {
        flex: 0 0 200px;
    }
    @media ${device.small} {
        height: auto;
        flex: 0 0 150px;
    }
`;

const Content = styled.article`
    flex: 1 1 auto;
    padding: 32px 39px;
    overflow: hidden;

    h2 {
        font-size: 36px;
        margin: 0;
    }

    h3 {
        font-size: 36px;
        margin: 0;
        color: var(--accent);
    }

    @media ${device.medium} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 15px 20px;
        
        h3, h2 {
            font-size: 28px;
        }
    }
`;