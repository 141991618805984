import { useState } from "react";
import { sendForm } from "../../utils";
import { NormalInput, TextArea } from "../../styles/Form.styles";
import { FormButton } from "../../styles/Button.styles";
import ModalTemplate from "./ModalTemplate";
import { useUser } from "../../context/UserContext";


const Contact = ({ showModal, setShowModal}) => {

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: ""
    });

    const { setBlurryBg, setShowErrorModal } = useUser()

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const res = await sendForm(formData);

        if(res.message === "Form Sent"){
            setBlurryBg(false);
            setShowModal(false);
            setFormData({
                name: "",
                email: "",
                message: ""
            })
        } else {
            setShowErrorModal(true);
            setShowModal(false);
        }

        console.log(res)
    };

    return (
        <ModalTemplate showModal={showModal} setShowModal={setShowModal}>
            <h2>Send us a message!</h2>
            <form onSubmit={handleSubmit}>
                <h3>Name:</h3>
                <NormalInput
                    type="text"
                    name="name"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
                <h3>Email:</h3>
                <NormalInput
                    type="email"
                    name="email"
                    placeholder="e.g. example@email.com"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
                <h3>Message:</h3>
                <TextArea
                    name="message"
                    placeholder="Your Message"
                    value={formData.message}
                    onChange={handleChange}
                    rows="5"
                    maxlength="500"
                    required
                ></TextArea>
                <FormButton type="submit">Send Message</FormButton>
            </form>
        </ModalTemplate>
    )
}

export default Contact;