import styled from "styled-components";
import AttributeInfo from "./Modals/AttributeInfo";
import { useState } from "react";
import { device } from "../styles/Responsive.style";

const AttributeBox = ({oldQuiz}) => {
    const [moreInfo, setMoreInfo] = useState(false);

  return (
    <>
      <GridWrapper>
        {oldQuiz ? (
            <>
            <div>
          <p>WAY</p>
          <ul>
            <li>Patient & Committed</li>
            <li>Efficiency & Improvement</li>
            <li>Resilient & Positive</li>
          </ul>
        </div>
        <div>
          <p>KNOW</p>
          <ul>
            <li>Curious & Inquisitive</li>
            <li>Detailed & Technically Minded</li>
          </ul>
        </div>
        <div>
          <p>HUMAN</p>
          <ul>
            <li>Communication & Empathy</li>
            <li>Team Player & Collaborative</li>
          </ul>
        </div>
        <div>
          <p>THINK</p>
          <ul>
            <li>Order & Logic</li>
            <li>Creative & Flexible</li>
            <li>Problem Solving</li>
          </ul>
        </div>
        </>
        ) : (
            <>
                <div>
                    <p>WAY</p>
                    <p>Adaptability & Resilience, Time Management, Growth Mindset</p>
                </div>
                <div>
                    <p>KNOW</p>
                    <p>Curiosity, Attention to Detail</p>
                </div>
                <div>
                    <p>HUMAN</p>
                    <p>Communication Skills, Empathy, Team Collaboration</p>
                </div>
                <div>
                    <p>THINK</p>
                    <p>Problem Solving, Critical Thinking</p>
                </div>
            </>
        )}
        
      </GridWrapper>
      <p onClick={() => setMoreInfo(true)}>More Information</p>

      <AttributeInfo oldQuiz={oldQuiz} showModal={moreInfo} setShowModal={setMoreInfo}/>
    </>
  );
};

export default AttributeBox;

const GridWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 650px;
  margin: 20px;

  div {
    width: 50%;
    background-color: rgba(245, 179, 47, 0.5);
    padding: 20px 10px;
    box-sizing: border-box;
  }

  div:nth-child(2),
  div:nth-child(3) {
    background-color: rgba(245, 179, 47, 0.25);
  }

  p {
    margin: 0;
  }
  ul {
    margin: 0;
    padding: 0 0 0 27px;
  }

  @media ${device.small} {
    margin: 20px;
  }
`;
