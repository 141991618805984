
const Plus = ({ strokeCol, width }) => {
    return (
        <svg style={{width: width}} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5 7C13.5 3.41015 10.5899 0.5 7 0.5C3.41015 0.5 0.5 3.41015 0.5 7C0.5 10.5899 3.41015 13.5 7 13.5C10.5899 13.5 13.5 10.5899 13.5 7Z" stroke={strokeCol}/>
            <path d="M7 4V10" stroke={strokeCol} strokeLinecap="round"/>
            <path d="M4 7H10" stroke={strokeCol} strokeLinecap="round"/>
        </svg>
    )
}

export default Plus;