const size = {
    small: "500px",
    medium: "750px",
    large: "1000px"
}

export const device = {
    small: `(max-width: ${size.small})`,
    medium: `(max-width: ${size.medium})`,
    large: `(max-width: ${size.large})`
}