import styled from "styled-components";
import ModalTemplate from "./ModalTemplate";
import { useAdmin } from "../../context/AdminContext";
import { GraySecondaryBtn, RedPrimaryBtn } from "../../styles/Button.styles";
import { useUser } from "../../context/UserContext";
import { deleteUsers } from "../../utils";


const DeleteUsers = ({ openDeleteModal, setDeleteModal, setOpenDeleteSuccessModal }) => {

    const { selectedUsers } = useAdmin();
    const { setBlurryBg, setShowErrorModal } = useUser();

    const cancelBtn = () => {
        setDeleteModal(false);
        setBlurryBg(false);
    }

    const handleDeleteBtn = async () => {

        const res = await deleteUsers(selectedUsers)

        if(res.message === "Successfully deleted users"){
            setOpenDeleteSuccessModal(true);
            setDeleteModal(false);
        } else {
            setShowErrorModal(true);
            setDeleteModal(false);
        }
    }

    return (
        <ModalTemplate showModal={openDeleteModal} setShowModal={setDeleteModal}>
            <h2>Are you sure?</h2>
            <p>Are you sure you want to delete these users? This is an irreversible action and will delete the accounts and data of all the selected users.</p>
            <p><RedBold>{selectedUsers.length} user accounts will be deleted.</RedBold> This means the results and access to DigiDNA for those individuals will be removed.</p>
            <p>You <RedBold>will not gain the users invite back</RedBold> by deleting them.</p>
            <p>If you want to continue and <RedBold>delete the {selectedUsers.length} users</RedBold> then click the red button below.</p>

            <Center>
                <GraySecondaryBtn onClick={cancelBtn}>Cancel</GraySecondaryBtn>
                <RedPrimaryBtn onClick={() => handleDeleteBtn()} $text="Delete Users"></RedPrimaryBtn>
            </Center>
        </ModalTemplate>
    );
};

export default DeleteUsers;


const RedBold = styled.b`
    color: var(--red);
`;

const Center = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 40px;
    gap: 20px;
`;