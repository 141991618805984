import styled from "styled-components";
import ModalTemplate from "./ModalTemplate";
import { GraySecondaryBtn, RedPrimaryBtn } from "../../styles/Button.styles";
import { useUser } from "../../context/UserContext";
import { deleteAdmin } from "../../utils";

const DeleteAdmin = ({ showModal, setShowModal, selectedAdmin, setOpenDeleteSuccessModal }) => {

    const { setBlurryBg, setShowErrorModal } = useUser();

    const cancelBtn = () => {
        setShowModal(false);
        setBlurryBg(false);
    }

    const handleDeleteBtn = async () => {
        const res = await deleteAdmin(selectedAdmin._id)

        if(res.message === "Successfully deleted admin and moved users"){
            setOpenDeleteSuccessModal(true);
            setShowModal(false);
        } else {
            setShowErrorModal(true);
            setShowModal(false);
        }
    }

    return (
        <ModalTemplate showModal={showModal} setShowModal={setShowModal}>
            <h2>Are you sure?</h2>
            <p>Are you sure you want to delete this Admin? This is an irreversible action and will move the accounts of all users under this admins account to a different account.</p>
            <p><RedBold>{selectedAdmin.name ? selectedAdmin.name : selectedAdmin.email}'s account will be deleted.</RedBold> This means the results and access to DigiDNA for this individual will be removed.</p>
            <p>There are <RedBold>{selectedAdmin.currentUsers} other accounts</RedBold> invited under this admin.</p>
            <p>If you want to continue and <RedBold>delete the {selectedAdmin.name ? selectedAdmin.name : selectedAdmin.email}'s account</RedBold> then click the red button below.</p>

            <Center>
                <GraySecondaryBtn onClick={cancelBtn}>Cancel</GraySecondaryBtn>
                <RedPrimaryBtn onClick={() => handleDeleteBtn()} $text="Delete Users"></RedPrimaryBtn>
            </Center>
        </ModalTemplate>
    );
};

export default DeleteAdmin;


const RedBold = styled.b`
    color: var(--red);
`;

const Center = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 40px;
    gap: 20px;
`;