import ModalTemplate from "./ModalTemplate.js";
import { PrimaryButton } from "../../styles/Button.styles.js";
import { useRef, useState } from "react";
import styled from "styled-components";
import { NormalInput, NumberInput } from "../../styles/Form.styles.js";
import Loader from "../Loader.js";
import { addNewAdmin } from "../../utils/index.js";

const AddAdmin = ({ showModal, setShowModal, setAllAdmins, setSuccessModal }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [warningMsg, setWarningMsg] = useState("");

    const emailRef = useRef();
    const inviteRef = useRef();

    const submitAddAdminUser = async (e) => {
        e.preventDefault()
        setIsLoading(true);

        const data = await addNewAdmin(emailRef.current.value.toLowerCase(), inviteRef.current.value);

        if(data.message === "Email already exists" || data.message === "Something Went Wrong. Please Try Again."){
            setWarningMsg(data.message);
            setIsLoading(false);
            return;
        }

        if(data.message === "Admin Successfully Added"){
            setAllAdmins((prev) => [...prev, {
                name: "N/A",
                email: emailRef.current.value,
                company: "N/A",
                currentUsers: 0,
                invites: inviteRef.current.value
            }])
            setShowModal(false);
            setIsLoading(false);
            setSuccessModal(true);
        }
    }


    return (
        <ModalTemplate showModal={showModal} setShowModal={setShowModal}>

            {isLoading && <LoaderWrapper><Loader themeType={"dark"}/></LoaderWrapper>}

            <h1>Add Admin</h1>
            <p>To add a new admin, enter the email address of the person that you want to add. Once submitted they will be emailed log in details.</p>
            
            <AddAdminSection onSubmit={submitAddAdminUser}>
                <h3>Email:</h3>    
                <NormalInput type="email" placeholder="e.g. example@eamil.com" ref={emailRef} required />

                <h3>Number of Invites:</h3>
                <p>This number is how many people the admin can invite, this can be changed later if they require more.</p>
                <NumberInput type="number" placeholder="10" max={9999} ref={inviteRef} required />
                <p className="warningText">{warningMsg}</p>
                <PrimaryButton type="submit">Add Admin</PrimaryButton>
            </AddAdminSection>

        </ModalTemplate>
    )
}

export default AddAdmin;

const AddAdminSection = styled.form`
    h3 {
        margin: 10px 0 5px 0;
    }

    p {
        margin: 5px 0;
    }

    .warningText {
        font-weight: 900;
        color: var(--red);
        height: 40px;
        display: flex;
        align-items: center;
        margin: 0;
    }

    button[type="submit"] {
        margin: 10px 0 0 0;
    }
`;

const LoaderWrapper = styled.div`
    position: absolute;
    height: calc(100% - 100px);
    width: calc(100% - 100px);
    background-color: white;
    z-index: 100;
    display: grid;
    place-items: center;
    border-top-right-radius: 100px;
`;