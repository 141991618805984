import ModalTemplate from "../ModalTemplate.js";
import { PrimaryButton } from "../../../styles/Button.styles.js";
import { useState } from "react";
import styled from "styled-components";
import { NormalInput } from "../../../styles/Form.styles.js";
import { addNewUsers } from "../../../utils/index.js";
import Loader from "../../Loader.js";
import * as XLSX from "xlsx";
import { useAdmin } from "../../../context/AdminContext.js";

const AddUser = ({ showModal, setShowModal, group_id, setOpenAddUserSuccessModal }) => {
    const [userEmails, setUserEmails] = useState([""]);
    const [warningMsg, setWarningMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const {adminData, setAdminData, setDisplayUsers, setAllUserData} = useAdmin();

    let extraData = {
        ageRange: "Age Range",
        education: "Highest Form of Education",
        prevTechSkill: "Experience in Tech Industry",
        status: "Employment Status",
        region: "Region",
        aspiration: "Aspirations in Tech Industry",
    } 

    const submitAddUserEmails = async (e) => {
        e.preventDefault();

        if([...new Set(userEmails)].length < userEmails.length){
            setWarningMsg("There are some duplicate emails");
            return
        } else if(userEmails.length === 0){
            setWarningMsg("Please enter an email address");
            return
        }

        setIsLoading(true)

        let cleanedEmails = userEmails.map((email) => email.toLowerCase())
        const data = await addNewUsers(cleanedEmails, group_id);

        if(data.message === "Users Successfully Added"){
            setAdminData((prev) => {
                let copy = prev
                copy.invites -= 1
                copy.groups.find(g => g._id === group_id).users.push(...data.user_ids)
                return copy
            });
            setUserEmails([""])
            setWarningMsg("")
            setAllUserData(prev => [...prev].concat(userEmails.map((e, i) => ({email: e, registered: false, results: [], _id: data.user_ids[i]}))));
            setDisplayUsers(prev => [...prev].concat(userEmails.map((e, i) => ({email: e, registered: false, results: [], _id: data.user_ids[i]}))));
            setIsLoading(false);
            setShowModal(false);
            setOpenAddUserSuccessModal(true);
            return;
        }
        // exisiting emails error
        if(data.message === "Some emails already exist"){
            setWarningMsg(`${data.message}: ${data.emails.map(e => e.email).join(", ")}`)
            setIsLoading(false)
            return
        }
        setWarningMsg(data.message)
        setIsLoading(false)
    }

    const addEmailInput = () => {
        if(userEmails.length === adminData.invites){
            setWarningMsg("Not enough Invites");
            return;
        }
        setWarningMsg("")
        setUserEmails([...userEmails, ""]);
    }

    const removeEmail = (id) => {
        let emailArr = [...userEmails];
        emailArr.splice(id, 1);
        setUserEmails(emailArr);
        setWarningMsg("")
    }

    const changeEmail = (id, e) => {
        let emailArr = [...userEmails];
        emailArr[id] = e.target.value;
        setUserEmails(emailArr);
    }

    const handleImportFile = async (e) => {
        setWarningMsg("")
        const file = e.target.files[0];
        const data = await file.arrayBuffer();
        const wb = XLSX.read(data);
        const ws = wb.Sheets[wb.SheetNames[0]];
    
        const jsonData = XLSX.utils.sheet_to_json(ws, {
          header: 1,
          defval: "",
        });
    
        const emails = []
    
        jsonData.forEach(row => row.forEach((txt) => {
          if (String(txt).match("^[a-zA-Z0-9_+&*-]+(?:\\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,7}$")) {
            emails.push(txt)
          }
        }))

        if(userEmails.length + emails.length > adminData.invites){
            let lostEmails = (userEmails.length + emails.length) - adminData.invites
            setUserEmails(prev => [...prev.filter(e => e !== ""), ...emails.slice(0, adminData.invites - userEmails.length)])
            setWarningMsg(`Not enough Invites. There were ${lostEmails} emails that could not be imported.`)
            return;
        }
        setUserEmails(prev => [...prev.filter(e => e !== ""), ...emails])
    }

    return (
        <ModalTemplate showModal={showModal} setShowModal={setShowModal}>

            {isLoading && <LoaderWrapper><Loader themeType={"dark"}/></LoaderWrapper>}

            <h1>Add Users - {adminData.groups.find(obj => obj._id === group_id).title}</h1>
            <p>This is the data collected for each user added under this group:</p>
            <List>
                <li>Email</li>
                <li>Name</li>
                {adminData.groups.find(obj => obj._id === group_id).captured_data.map((data, i) => <li key={i}>{extraData[data]}</li>)}
            </List>
            <p>These are the skills that users select from when joining this group:</p>
            {!adminData.groups.find(obj => obj._id === group_id)?.skills || adminData.groups.find(obj => obj._id === group_id)?.skills?.length === 0 ? (
                <p style={{textAlign:"center"}}><b>No skills captured for this group</b></p>
            ) : (
                <List>
                    {adminData.groups.find(obj => obj._id === group_id).skills.map((data, i) => <li key={i}>{data}</li>)}
                </List>
            )}

            <InviteLink>
                <h3>Invite Link</h3>
                <p>This link can be distributed by you, however each person who signs up will be added to this group and an invite will be removed from your total.</p>
                <NormalInput readOnly value={`${window.location.origin}/#/invite/${adminData.company_id}/${group_id}`}/>
            </InviteLink>
            
            <ImportFileWrapper>
                <label htmlFor="importFile" className="importBtn">Import xlsx file</label>
                <input id="importFile" type="file" accept=".xlsx" onChange={handleImportFile}/>
            </ImportFileWrapper>

            <UserEmailInput onSubmit={submitAddUserEmails}>
                <h3>User(s) email:</h3>
                <p>To add a new user, enter the email address of the person that you want to add. To add more than one student click the add once submitted they will receive log in details automatically.</p>
                {userEmails.map((email, index) => {
                    return(
                        <InputWrapper key={index}>
                            <NormalInput type="email" value={email} onChange={e => changeEmail(index, e)} placeholder="Enter User email" required/>
                            <button aria-label="Delete User Email" type="button" onClick={() => removeEmail(index)}></button>
                        </InputWrapper>
                    )
                })}
                <button onClick={addEmailInput}>+ Add new line</button>
                <p className="warningText">{warningMsg}</p>
                <div className="buttonWrap">
                    <PrimaryButton type="submit" aria-label="Add User">Add User(s)</PrimaryButton>
                </div>
            </UserEmailInput>
        </ModalTemplate>
    )
}

export default AddUser;


const List = styled.ul`
    margin: 10px 0;
    column-count: 2;
    column-gap:20px;
`;

const InviteLink = styled.div`
    margin: 30px 0;
    input {
        &:focus {
            outline: none;
        }
    }
`;

const ImportFileWrapper = styled.div`
    #importFile {
        display: none;
    }

    .importBtn {
        border: 3px solid var(--accent);
        border-radius: 5px;
        background-color: var(--accent);
        font-size: 13px;
        font-weight: 900;
        color: white;
        cursor: pointer;
        letter-spacing: 1px;
        box-shadow: 0 0 0 0px white inset;
        transition: all 0.2s;
        text-decoration: none;
        padding: 5px 15px;
        margin: 0 10px 0 0;

        &:hover {
            box-shadow: 0 0 0 2px white inset;
            background-color: var(--accent-hover);
        }
    }
`;

const UserEmailInput = styled.form`
    h3 {
        margin: 18px 0 0 0;
    }

    & > button {
        font-size: 16px;
        width: 100%;
        text-align: left;
        padding: 9px 11px;
        margin: 5px 0 0 0;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        background-color: var(--secondary-gray);
        color: var(--text-main);
        transition: all 0.2s;

        &:hover{
            background-color: #c9c9c9;
        }
    }

    .warningText {
        font-weight: 900;
        color: var(--red);
        height: 40px;
        display: flex;
        align-items: center;
        margin: 0;
    }
`;

const InputWrapper = styled.div`
    display: flex;
    align-items: center;

    button {
        position: relative;
        border: 1px solid var(--red);
        border-radius: 5px;
        height: 39px;
        width: 39px;
        cursor: pointer;
        background-color: white;
        transition: all 0.2s;

        &:hover {
            background-color: #ffcfcf;
        }

        &:before, &:after {
            position: absolute;
            top: 9px;
            left: 18px;
            content: ' ';
            height: 20px;
            width: 1px;
            background-color: var(--red);
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
`;

const LoaderWrapper = styled.div`
    position: absolute;
    height: calc(100% - 100px);
    width: calc(100% - 100px);
    background-color: white;
    z-index: 100;
    display: grid;
    place-items: center;
    border-top-right-radius: 100px;
`;