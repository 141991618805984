import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import PrivateRoute from './components/PrivateRoute.js';

import Home from "./pages/Home.js";
import Login from './pages/Login.js';
import Quizzes from './pages/Quizzes.js';
import AdminDash from './pages/AdminDash.js';
import SupAdminDash from './pages/SupAdminDash.js';
import Quiz from './pages/Quiz.js';
import Results from './pages/Results.js';
import AdminResults from './pages/AdminResults.js';
import Layout from './components/Layout.js';
import ForgotPassword from './pages/ForgotPassword.js';
import ResetPassword from './pages/ResetPassword.js';
import Register from './pages/Register.js';
import Invite from './pages/Invite.js';
import { AdminProvider } from './context/AdminContext.js';
import ScrollToTop from './components/ScrollToTop.js';
import PrivacyNotice from './pages/PrivacyNotice.js';

const App = () => {
  const location = useLocation();
  return (
    <>
      <ScrollToTop>
        <AnimatePresence mode="wait" initial={false}>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={ <Layout><Home /></Layout> } />
            <Route path="/privacyNotice" element={ <Layout><PrivacyNotice /></Layout> } />
            <Route path="/login" element={ <Layout><Login /></Layout> } />
            <Route path="/forgotPassword" element={ <Layout><ForgotPassword /></Layout> } />
            <Route path="/passwordReset" element={ <Layout><ResetPassword /></Layout> } />
            <Route path="/register" element={<Layout><Register /></Layout>} />
            <Route path="/invite/:companyId/:groupId" element={ <Layout><Invite /></Layout> } />
            <Route path="/questionnaires" element={
              <PrivateRoute roles={["User", "Admin", "SuperAdmin"]}>
                <Layout>
                  <Quizzes /> 
                </Layout>
              </PrivateRoute> 
            } />
            <Route path="/questionnaires/:id/:name" element={
              <PrivateRoute roles={["User", "Admin", "SuperAdmin"]}>
                <Layout>
                  <Quiz /> 
                </Layout>
              </PrivateRoute> 
            } />
            <Route path="/adminDash" element={
              <PrivateRoute roles={["Admin"]}>
                <Layout>
                  <AdminProvider>
                    <AdminDash /> 
                  </AdminProvider>
                </Layout>
              </PrivateRoute> 
            } />
            <Route path="/supAdminDash" element={
              <PrivateRoute roles={["SuperAdmin"]}>
                <Layout>
                  <SupAdminDash /> 
                </Layout>
              </PrivateRoute> 
            } />
            <Route path="/results/:quizId" element={
              <PrivateRoute roles={["User", "Admin", "SuperAdmin"]}>
                <Layout>
                  <Results /> 
                </Layout>
              </PrivateRoute> 
            } />
            <Route path="/results/:quizId/:userId" element={
              <PrivateRoute roles={["Admin"]}>
                <Layout>
                  <AdminResults />
                </Layout> 
              </PrivateRoute> 
            } />
          </Routes>
        </AnimatePresence>
      </ScrollToTop>
    </>
  );
}

export default App;
