import { predictedJobs } from "./predictedJobs";
import * as XLSX from "xlsx";
import ResultReportTable from "../components/PDFTableReport";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import ResultReport from "../components/PDFReport";

export const calculateResults = (adminData, quiz, users) => {
    let att = [...new Set(quiz.questions.map(q => q.attribute))]

    let userReportData = [];
    users.forEach((user) => {
        if(!user.results.find(r => r.quizId === quiz._id)){
            userReportData.push({email: user.email, userId: user._id, name: user.name, scores: att.map((at) => "N/A")})
            return;
        }
        let scores = att.map((el) => [])
        user.results.find(r => r.quizId === quiz._id).answers.forEach((answer) => {
            let questionObj = quiz.questions.find(q => q._id === answer.question_id);
            let chosenAnsObj = questionObj.answers.find(ans => ans._id === answer.answer_id);
            scores[att.indexOf(questionObj.attribute)].push(chosenAnsObj.points);
        })
        userReportData.push({email: user.email, userId: user._id, name: user.name, scores: scores.map((att) => (att.reduce(( p, c ) => p + c, 0 ) / att.length).toFixed(1))})
    })

    return {compName: adminData.company, quizName: quiz.title, usersData: userReportData, attributes: att }
}

export const getPDFTable = async (adminData, quiz, users) => {
    const props = await calculateResults(adminData, quiz, users);
    const doc = <ResultReportTable {...props} />;
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, 'digiDNA-table-report.pdf');
}

export const getPDFCharts = async (images) => {
    const doc = <ResultReport images={images} />;
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, 'digiDNA-report.pdf');
}

export const getCSVData = (adminData, quiz, users) => {

    let data = calculateResults(adminData, quiz, users)
    let csvData = []

    csvData.unshift([`digiDNA ${data.quizName} Results for ${adminData.company}`])

    csvData.push(["Name", "Email", ...data.attributes, "Total", "Matched Jobs", "", "", "Age Range", "Highest Education", "Prev. Tech Skills", "Employment Status", "Region", "Aspirations", "Skills"])
    let extraDataOptions = ["ageRange", "education", "prevTechSkill", "stauts", "region", "aspiration"]
    
    data.usersData.forEach(user => {
        let userData = users.find(u => u._id === user.userId)
        let jobs = user.scores.includes("N/A") ? [{job:"N/A"}, {job:"N/A"}, {job:"N/A"}] : predictedJobs(data.attributes, user.scores.map(s => parseFloat(s)))
        let extraInfo = userData.registered && userData.extra_data ? extraDataOptions.map(t => userData.extra_data[t] ? userData.extra_data[t] : "N/A") : ["N/A","N/A","N/A","N/A","N/A","N/A","N/A"]
        csvData.push([user.name, user.email, ...user.scores, user.scores.includes("N/A") ? "N/A" : user.scores.reduce((a, b) => parseInt(a) + parseInt(b)), ...jobs.map(j => j.job), ...extraInfo, userData.extra_data?.skills ? userData.extra_data.skills.join(", ") : "N/A"])
    })

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(csvData);

    XLSX.utils.book_append_sheet(wb, ws, "Questionnaire Results");

    XLSX.writeFile(wb, "digiDNA-results.xlsx");
}
