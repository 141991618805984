import { motion } from "framer-motion"
import Loader from "./Loader.js";
import styled from "styled-components";

const PageTransition = ({ isLoading }) => {
    return (
        <Wrap>
            {/* Slide In Animation */}
            <motion.div 
                className="slide-in-front"
                initial={{ x: "-100%" }}
                animate={{ x: "-100%" }}
                exit={{ x: "0" }}
                transition={{ duration: 0.5, ease: "easeIn", delay: 0.05 }}
            >
            </motion.div>
            <motion.div 
                className="slide-in-back"
                initial={{ x: "-100%" }}
                animate={{ x: "-100%" }}
                exit={{ x: "0" }}
                transition={{ duration: 0.5, ease: "easeIn" }}
            />   
            {/* Slide Out Animation with Loader */}
            <motion.div 
                className="slide-out-front"
                animate={{ x: isLoading ? "0" : "100%" }}
                transition={{ duration: 0.5, ease: "easeInOut"}}
            >
                <Loader themeType={"light"} />
                <PageLogo><span>digi</span>DNA</PageLogo>
            </motion.div>
            <motion.div 
                className="slide-out-back"
                animate={{ x: isLoading ? "0" : "100%" }}
                transition={{ duration: 0.5, ease: "easeInOut", delay: 0.07}}
            >
            </motion.div>            
        </Wrap>
    )
}

export default PageTransition;

const Wrap = styled.div`
    position: relative;
    z-index:999;
`;

const PageLogo = styled.h1`
    position: relative;
    top: 0px;
    margin: 0;
    opacity: 0;
    color: white;
    font-size: 45px;
    animation: 0.5s moveDown forwards;
    animation-delay: 0.25s;

    span {
        color: white;
        font-weight: 400;
    }

    @keyframes moveDown {
        from {
            top: 15px; 
            opacity: 0;
        }
        to {
            top: 20px; 
            opacity: 1;
        }
    }
`;