import styled from 'styled-components';
import smallArrow from '../../assets/icons/small-arrow.png';
import search from '../../assets/icons/search-icon.png';
import downArrow from '../../assets/icons/down-arrow.svg';
import xIcon from '../../assets/icons/x.png';
import { device } from '../../styles/Responsive.style';
import { useState, useEffect } from 'react';

const SupAdminTable = ({ allAdmins, displayAllAdmins, setDisplayAllAdmins, handleEditAdmin, handleDeleteAdmin }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage, setUsersPerPage] = useState(20); // 20
    const [sorted, setSorted] = useState("none");
    const [visibleBtns, setVisibleBtns] = useState([]);

    const changePage = () => {
        let displayed_buttons = []
        let numberOfPages = Math.ceil(displayAllAdmins.length / usersPerPage)
        if (numberOfPages > 7) {
            if (currentPage > 4) {
                if (numberOfPages - currentPage < 4) {
                    displayed_buttons.unshift(1, "...")
                    for (let i = numberOfPages - 4; i <= numberOfPages; i++) {
                        displayed_buttons.push(i)
                    }
                } else {
                    displayed_buttons = [1, "...", currentPage - 1, currentPage, currentPage + 1, "...", numberOfPages]
                }
            }
            if (currentPage <= 4) {
                displayed_buttons = [1, 2, 3, 4, 5, "...", numberOfPages]
            }
        } else {
            for (let i = 1; i <= numberOfPages; i++) {
                displayed_buttons.push(i)
            }
        }
        setVisibleBtns([...displayed_buttons])
    }

    const searchName = (e) => {
        if (e.target.value.length > 0) {
            setDisplayAllAdmins(allAdmins.filter(user => {
                let name = user.name ? user.name.toLowerCase() : 'N/A';
                return name.toLowerCase().match(e.target.value.toLowerCase().trim())
            }))
        } else {
            setDisplayAllAdmins(allAdmins)
        }
    }

    const sortNames = () => {
        if (sorted === "none" || sorted === "z-a") {
            setDisplayAllAdmins(prev => [...prev.sort((a, b) => {
                const nameA = a.name ? a.name.toLowerCase() : 'zzz';
                const nameB = b.name ? b.name.toLowerCase() : 'zzz';
                if (nameA < nameB) return -1;  
                if (nameA > nameB) return 1;
                return 0;   
            })])
            setSorted("a-z")
        } else {
            setDisplayAllAdmins(prev => [...prev.sort((a, b) => {
                const nameA = a.name ? a.name.toLowerCase() : 'zzz';
                const nameB = b.name ? b.name.toLowerCase() : 'zzz';
                if (nameA > nameB) return -1;  
                if (nameA < nameB) return 1;
                return 0;   
            })])
            setSorted("z-a")
        }
    }

    useEffect(() => {
        changePage();
    }, [currentPage, displayAllAdmins])


    return (
        <TableSectionWrapper>
            <TableInteractions>
                <LeftSide>
                    <p>Showing {((currentPage - 1) * usersPerPage) + 1} - {(currentPage * usersPerPage > displayAllAdmins.length) ? displayAllAdmins.length : currentPage * usersPerPage} of {displayAllAdmins.length}</p>
                    <SearchBox>
                        <input type="search" onChange={(e) => searchName(e)} id="searchName" placeholder="Search Name" />
                        <label htmlFor="searchName"><img src={search} alt="search icon" /></label>
                    </SearchBox>
                </LeftSide>
                <RightSide>
                    <MovePageBtns>
                        <button className="prevBtn" onClick={() => currentPage !== 1 ? setCurrentPage(currentPage - 1) : null}>
                            <img src={smallArrow} alt="arrow pointing left" />
                        </button>
                        <button className="nextBtn" onClick={() => currentPage !== Math.ceil(displayAllAdmins.length / usersPerPage) ? setCurrentPage(currentPage + 1) : null}>
                            <img src={smallArrow} alt="arrow pointing right" />
                        </button>
                    </MovePageBtns>
                </RightSide>
            </TableInteractions>

            <TableWrapper>
                <TableHead id="table-head">
                    <span onClick={() => sortNames()}>Name <img src={downArrow} className={sorted === "none" || sorted === "a-z" ? "down" : "up"} alt="down arrow" /></span>
                    <span>Email</span>
                    <span>Company</span>
                    <span>Invited Users</span>
                    <span>Invites Remaining</span>
                    <span></span>
                </TableHead>

                {displayAllAdmins.length === 0 && (
                    <NoUsers>No Users Found</NoUsers>
                )}

                {displayAllAdmins.slice(currentPage === 1 ? 0 : (currentPage - 1) * usersPerPage, (currentPage * usersPerPage)).map((user, index) => {
                    return (
                        <TableRow key={index}>
                            <span>{user.name ? user.name : 'N/A'}</span>
                            <span>{user.email}</span>
                            <span>{user.company ? user.company : 'N/A'}</span>
                            <span>{user.currentUsers}</span>
                            <span>{user.invites}</span>
                            <span onClick={() => handleEditAdmin(user)}><p>Edit</p></span>
                            {user.email === 'john.muir@wearecodenation.com' ? <span></span> : <span onClick={() => handleDeleteAdmin(user)}><p className='delete'>Delete</p></span>}
                        </TableRow>
                    )
                })}

                <TableFooter className="footer">
                    <PaginationWrapper>
                        <button className="moveBtn left" onClick={() => currentPage !== 1 ? setCurrentPage(currentPage - 1) : null}><img src={smallArrow} alt="arrow pointing left" /></button>
                            {visibleBtns.map((btn, index) => (
                                btn === "..." ? <p key={index}>...</p> : <button key={index} onClick={() => setCurrentPage(btn)} className={currentPage === btn ? "current-page" : ""}>{btn}</button>
                            ))}
                        <button className="moveBtn right" onClick={() => currentPage !== Math.ceil(displayAllAdmins.length / usersPerPage) ? setCurrentPage(currentPage + 1) : null}><img src={smallArrow} alt="arrow pointing right" /></button>
                    </PaginationWrapper>
                </TableFooter>
            </TableWrapper>

        </TableSectionWrapper>
    )
}

export default SupAdminTable;

const TableSectionWrapper = styled.section`
    padding: 10px;
    box-shadow: 0 2px 10px 0 var(--gray-light);
    border-radius: 10px;
`;

const TableInteractions = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 10px;

    p {
        font-size: var(--font-small);
        background-color: var(--bg-main-light);
        margin: 0;
        padding: 10px 20px;
        border-radius: 5px;
    }

    @media ${device.medium} {
        flex-direction: column;
        align-items: flex-end;
    }
`;

const LeftSide = styled.div`
    display: flex;
    gap: 15px;

    @media ${device.medium} {
        width: 100%;
    }

    @media ${device.small} {
        flex-direction: column;
    }
`;

const RightSide = styled.div`
    display: flex;
    gap: 15px;

    @media ${device.small} {
        width: 100%;
        justify-content: space-between;
    }
`;

const SearchBox = styled.div`
    border: 1px solid var(--gray-light);
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    img {
        height: 18px;
        padding: 0 5px 0 15px;
        opacity: 0.4;
        transition: opacity 0.2s;
    }

    label {
        display: flex;
        align-items: center;
        cursor: text;
    }
    
    input {
        border: none;
        font-size: var(--font-small);
        padding: 10px;

        &:focus {
            outline: none;
        }

        &::-webkit-search-cancel-button {
            -webkit-appearance: none;
            height: 20px;
            width: 20px;
            background: url(${xIcon});
            background-size: contain;
        }
    }

    input:focus + label img {
        opacity: 1;
    }

    @media ${device.medium} {
        flex: 1;
        justify-content: flex-end;
    }
`;

const MovePageBtns = styled.div`
    display: flex;

    button {
        border: 1px solid var(--gray-light);
        background-color: transparent;
        display: flex;
        align-items: center;
        padding: 0 10px;
        height: 40px;
        cursor: pointer;
        transition: background-color 0.2s;

        &.prevBtn {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            border-right: none;
        }
        &.nextBtn {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        &:hover {
            background-color: var(--bg-main-light);
        }
    }

    img {
        height: 15px;
    }

    .prevBtn img {
        transform: rotate(90deg);
    }
    .nextBtn img {
        transform: rotate(-90deg);
    }
`;

const TableWrapper = styled.section`
    border: 1px solid var(--gray-light);
    width: 100%;
    border-radius: 10px;
    padding: 10px 10px 0;
    box-sizing: border-box;

    & > div:not(.footer) {
        display: grid;
        grid-template-columns: 1fr 2fr 2fr 100px 100px 70px 70px;
    }

    @media ${device.large} {
        & > div:not(.footer) {
            grid-template-columns: 1fr 2fr 1fr 70px 70px;
            grid-template-rows: 1fr 1fr;
        }
    }

    @media ${device.medium} {
        & > div:not(.footer) {
            grid-template-columns: 2fr 1fr 90px;
        }
    }

    @media ${device.small} {
        & > div:not(.footer) {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr 1fr 1fr;
        }
    }
`;

const TableHead = styled.div`
    background-color: var(--bg-main-light);
    text-transform: uppercase;
    color: var(--gray);
    display: flex;
    align-items: center;
    
    border-radius: 5px;
    padding: 10px;
    font-weight: 900;
    margin-bottom: 5px;

    span:first-child {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    img {
        height: 20px;
        margin-left: 10px;
        transition: transform 0.2s;
    }

    .down {
        transform: rotate(0deg);
    }
    .up {
        transform: rotate(180deg);
    }

    @media ${device.large} {
        &#table-head {
            display: none;
        }
    }
`;

const TableRow = styled.div`
    padding: 5px;
    position: relative;
    border-bottom: 1px solid var(--gray-light);

    &::before {
        position: absolute;
        content: "";
        height: 1px;
        width: calc(100% + 20px);
        background-color: var(--gray-light);
        left: -10px;
        bottom: -1px;
    }

    &:hover {
        background-color: var(--bg-main-light); 
    }

    span {
        overflow: hidden;
        text-overflow: ellipsis; 
        white-space: nowrap;
        font-size: var(--font-small);
        margin: auto 0;
    }

    .viewBtn {
        display: flex;
        align-items: center;
        color: var(--accent);
        font-weight: 900;
        cursor: pointer;
    }

    p {
        font-weight: 900;
        color: var(--accent);
        margin: 0;
        cursor: pointer;
        padding: 5px 0;
        text-align: center;
    }

    .delete {
        color: var(--red);
    }

    img {
        height: 20px;
        margin-left: 10px;
    }

    @media ${device.large} {
        span:nth-child(1) {
            grid-row-start: 1;
            grid-row-end: span 2;
        }
        span:nth-child(2) {
            grid-column-start: 2;
            grid-row-start: 1;
        }
        span:nth-child(3) {
            grid-column-start: 2;
            font-weight: 900;
        }
        span:nth-child(4) {
            grid-column-start: 3;
            grid-row-start: 1;
            &::before {
                content: "Invited Users: ";
            }
        }
        span:nth-child(5) {
            grid-column-start: 3;
            grid-row-start: 2;
            &::before {
                content: "Invites Remaining: ";
            }
        }
        span:nth-child(6) {
            grid-column-start: 4;
            grid-row-start: 1;
            grid-row-end: span 2;
        }
        span:nth-child(7) {
            grid-column: 5;
            grid-row: 1 / span 2;
        }
    }

    @media ${device.medium} {
        span:nth-child(1) {
            grid-row-start: 1;
            grid-row-end: 2;
        }
        span:nth-child(3) {
            grid-column-start: 1;
            grid-row-start: 2;
        }
        span:nth-child(2) {
            visibility: hidden;
            width: 0;
        }
        span:nth-child(4) {
            grid-column: 2 / span 3;
            grid-row-start: 1;
        }
        span:nth-child(5) {
            grid-column: 2 / span 3;
            grid-row-start: 2;
        }
        span:nth-child(6) {
            grid-row: 1;
        }
        span:nth-child(7) {
            grid-column: 4;
            grid-row: 2;
        }
    }

    @media ${device.small} {
        span:nth-child(1) {
            grid-column: 1 / span 2;
            grid-row-start: 1;
        }
        span:nth-child(4) {
            grid-column: 1 / span 2;
            grid-row-start: 3;
        }
        span:nth-child(5) {
            grid-column: 1 / span 2;
            grid-row-start: 4;
        }
        span:nth-child(6) {
            grid-column-start: 2;
            grid-row: 1 / span 2;
        }
        span:nth-child(7) {
            grid-column: 2;
            grid-row: 3 / span 4;
        }
    }
`;

const TableFooter = styled.div`
    padding: 15px 50px;

    @media ${device.small} {
        padding: 10px 0px;
    }
`;

const NoUsers = styled.p`
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
    color: var(--gray);
    font-size: var(--font-small);
    margin: 30px 0;
`;

const PaginationWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    
    p {
        font-size: var(--font-small);
        margin: 0;
        width: 30px;
        text-align: center;
    }

    button {
        height: 30px;
        width: 30px;
        font-size: var(--font-small);
        cursor: pointer;
        border-radius: 5px;
        border: none;
        background-color: transparent;
        transition: background-color 0.2s;

        &:not(.current-page):not(.moveBtn):hover {
            background-color: var(--accent-hover);
        }

        &.moveBtn:hover {
            background-color: var(--bg-main-light);
        }
    }

    .current-page {
        background-color: var(--accent);
        color: white;
        font-weight: 900;
    }

    .moveBtn {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .moveBtn img {
        height: 10px;
    }

    .left {
        transform: rotate(90deg);
    }
    .right {
        transform: rotate(-90deg);
    }
`;