import { createContext, useContext, useState } from "react";

const AdminContext = createContext();

// PAGINATION does not update when searching names

export const AdminProvider = ({ children }) => {
    const [allUserData, setAllUserData] = useState([]); // stores initial pull of all users - not to be edited
    const [displayUsers, setDisplayUsers] = useState([]);
    const [adminData, setAdminData] = useState({}); // holds admin data from pull for groups
    const [selectedGroup, setSelectedGroup] = useState("all"); // what group is being selected
    const [currentPage, setCurrentPage] = useState(0);
    const [usersPerPage, setUsersPerPage] = useState(20); // 20
    const [selectedUsers, setSelectedUsers] = useState([]); // selected from table
    const [namesSorted, setNamesSorted] = useState(true);
        
    // info for spider and downloads
    const [pdfReportData, setPdfReportData] = useState([]);
    const [allQuizzes, setAllQuizzes] = useState([]);
    const [selectedQuizId, setSelectedQuizId] = useState("");
    const [generateSpiders, setGenerateSpiders] = useState(false);
    const [generateSpidersForSelected, setGenerateSpidersForSelected] = useState(false);
    const [compareUsers, setCompareUsers] = useState([]); // selected in the comparison spider charts
    const [averageUri, setAverageUri] = useState({});
    const [spiderData, setSpiderData] = useState({}); // for the average spider chart

    // modals
    const [selectedUser, setSelectedUser] = useState({}); // for the view results button
    const [noInviteModal, setNoInviteModal] = useState(false);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [viewResultsModal, setViewResultsModal] = useState(false);

    return (
        <AdminContext.Provider value={{ 
            allUserData, 
            setAllUserData, 
            selectedGroup, 
            setSelectedGroup, 
            adminData, 
            setAdminData, 
            displayUsers, 
            setDisplayUsers, 
            currentPage, 
            setCurrentPage, 
            usersPerPage, 
            selectedUsers, 
            setSelectedUsers, 
            namesSorted, 
            setNamesSorted,
            noInviteModal,
            setNoInviteModal,
            showAddUserModal,
            setShowAddUserModal,
            selectedUser,
            setSelectedUser,
            successModal, 
            setSuccessModal,
            viewResultsModal, 
            setViewResultsModal,
            pdfReportData,
            setPdfReportData,
            allQuizzes,
            setAllQuizzes,
            selectedQuizId,
            setSelectedQuizId,
            generateSpiders,
            setGenerateSpiders,
            generateSpidersForSelected,
            setGenerateSpidersForSelected,
            compareUsers, 
            setCompareUsers,
            averageUri, 
            setAverageUri,
            spiderData, 
            setSpiderData
        }}>
            {children}
        </AdminContext.Provider>
    )
}

export const useAdmin = () => {
    return useContext(AdminContext);
}