import { useEffect, useState } from "react";
import ModalTemplate from "./ModalTemplate.js";
import { getQuizName } from "../../utils/index.js";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../context/UserContext.js";

const AdminViewResults = ({ showModal, setShowModal, userInfo }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [quizNames, setQuizNames] = useState([]);
    const navigate = useNavigate();
    const { setBlurryBg } = useUser()
    useEffect(() => {
        const fetchName = async () => {
            const data = await getQuizName();
            setQuizNames(data);
            setIsLoading(false);
        }
        fetchName()
    }, [])

    const handleMovePage = (url) => {
        setShowModal(false);
        setBlurryBg(false);
        navigate(url)
    }

    return (
        <ModalTemplate showModal={showModal} setShowModal={setShowModal}>
            <h1>View Results</h1>
            <h2>{userInfo.name}</h2>
            <p>Completed Questionnaires:</p>
            {!isLoading && (
                <>
                    {userInfo.results?.map((quiz, index) => {
                        return (
                            <ResWrapper key={index}>
                                <p>{quizNames.filter(q => q._id === quiz.quizId)[0].title} Results</p>
                                <ViewLink onClick={() => handleMovePage(`/results/${quizNames.filter(q => q._id === quiz.quizId)[0]._id}/${userInfo._id}`)}>View Results</ViewLink>
                            </ResWrapper>
                        )
                    })}
                </>
            )}
        </ModalTemplate>
    )
}

export default AdminViewResults;

const ResWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    background-color: var(--primary-gray);
    padding: 0 30px;
    margin: 2px 0;
`;

const ViewLink = styled.p`
        font-weight: 900;
        color: var(--accent);
        margin: 0 0 0 10px;
        cursor: pointer;
        padding: 5px 0;
`;