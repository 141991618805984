import styled from "styled-components"
import { useAdmin } from "../../context/AdminContext";
import { useEffect, useState } from "react";
import { device } from "../../styles/Responsive.style";
import DeleteUsers from "../Modals/DeleteUsers";
import { GrayPrimaryBtn, RedPrimaryBtn } from "../../styles/Button.styles";
import deleteIcon from "../../assets/icons/trash.png";
import downloadIcon from "../../assets/icons/download.png";
import compareIcon from "../../assets/icons/compare.png";
import { getCSVData, getPDFTable } from "../../utils/downloads";
import DeleteSuccess from "../Modals/DeleteSuccess";

const TableToolBar = () => {
    const [showToolBar, setShowToolBar] = useState(false);
    const { selectedUsers, setSelectedUsers, adminData, allQuizzes, selectedQuizId, allUserData, setGenerateSpidersForSelected, setCompareUsers } = useAdmin();
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [openDeleteSuccessModal, setOpenDeleteSuccessModal] = useState(false);
    const [openDownloadDropdown, setOpenDownloadDropdown] = useState(false);

    useEffect(() => {
        (selectedUsers.length === 0) ? setShowToolBar(false) : setShowToolBar(true)
    }, [selectedUsers])

    const userToCompare = () => {
        let completedQuiz = []
        selectedUsers.forEach((user) => {
            if(allUserData.find((ud) => ud._id === user).results.length === 0) return
            if(allUserData.find((ud) => ud._id === user).results.find((quiz) => quiz.quizId === selectedQuizId).date_completed !== null){
                completedQuiz.push(user)
            }
        })
        setCompareUsers(completedQuiz);
    }

    return (
        <>
            <ToolBarSection $open={showToolBar}>
                <ToolBarWrapper>
                    <Screen onClick={() => setOpenDownloadDropdown(false)} className={openDownloadDropdown ? "open-screen" : ""} />
                    <p>{selectedUsers.length} User Selected</p>
                    <GrayPrimaryBtn onClick={userToCompare} $text="Compare User" $icon={compareIcon} />
                    <DownloadDropDown onClick={() => setOpenDownloadDropdown(!openDownloadDropdown)}>
                        <DownloadBtn className={openDownloadDropdown ? "open-btn" : ""} $text="Download" $icon={downloadIcon} />
                        <DropdownOptions className={openDownloadDropdown ? "open" : ""}>
                            <button onClick={() => getPDFTable(adminData, allQuizzes.find(q => q._id === selectedQuizId), selectedUsers.map((u) => allUserData.find(uObj => uObj._id === u)))}>as PDF - Table</button>
                            <button onClick={() => setGenerateSpidersForSelected(true)}>as PDF - Radars</button>
                            <button onClick={() => getCSVData(adminData, allQuizzes.find(q => q._id === selectedQuizId), selectedUsers.map((u) => allUserData.find(uObj => uObj._id === u)))}>as XLSX</button>
                        </DropdownOptions>
                    </DownloadDropDown>
                    <RedPrimaryBtn onClick={() => setDeleteModal(true)} $text="Delete Selected" $icon={deleteIcon} />
                    <CloseBtn onClick={() => setSelectedUsers([])}></CloseBtn>
                </ToolBarWrapper>
            </ToolBarSection>
            <DeleteUsers openDeleteModal={openDeleteModal} setDeleteModal={setDeleteModal} setOpenDeleteSuccessModal={setOpenDeleteSuccessModal} />

            <DeleteSuccess openDeleteSuccessModal={openDeleteSuccessModal} setOpenDeleteSuccessModal={setOpenDeleteSuccessModal} />
        </>
    )
}

export default TableToolBar;

const ToolBarSection = styled.section`
    width: 100vw;
    position: fixed;
    display: flex;
    justify-content: center;
    transition: bottom 0.2s;
    bottom: ${(props) => props.$open ? "50px" : "-100px"};
    left: 0;
    z-index: 4;
    pointer-events: none;
`;

const ToolBarWrapper = styled.div`
    background-color: var(--text-main);
    color: var(--bg-main);
    gap: 10px;
    border-radius: 10px;
    padding: 13px;
    box-shadow: 0 2px 10px 0 var(--gray-light);
    display: flex;
    align-items: center;
    pointer-events: all;

    p {
        font-size: var(--font-small);
        margin: 0;
        width: 140px;
        white-space: nowrap;
        color: var(--text-light);
    }

    @media ${device.small} {
        flex-wrap: wrap;
        width: 220px;

        p {
            width: 100%;
        }
    }
`;

const DownloadDropDown = styled.div`
    position: relative;
    z-index: 15;
`;

const DownloadBtn = styled.button`
    border-radius: 5px;
    border: none;
    white-space: nowrap;
    padding: 0.7em 2.2em;
    font-weight: 900;
    font-size: var(--font-small);
    color: white;
    cursor: pointer;
    box-sizing: border-box;
    border: 1px solid transparent;
    transition: background-color 0.2s;
    background-color: var(--gray);

    &:hover {
        background-color: var(--gray-dark);
    }
    &:active {
        border: 1px solid var(--gray-light);
    }

    &::before {
        content: "${(p) => p.$text}"
    }

    &.open-btn {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border: 1px solid var(--gray-dark);
    }

    @media ${device.medium} {
        ${({ $icon }) => $icon && `
            padding: 0.5em 1em;
            &::before {
                content: "";
                height: 24px;
                width: 24px;
                display: block;
                background: url(${$icon});
                background-size: 100%;
                filter: invert(1);
            }
        `}
    }
`;

const DropdownOptions = styled.div`
    display: none;
    flex-direction: column;
    position: absolute;
    width: 100%;
    bottom: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    min-width: max-content;

    button {
        text-align: left;
        background-color: var(--gray);
        border: 1px solid var(--gray-dark);
        border-bottom: none;
        font-size: var(--font-small);
        padding: 10px 15px;
        cursor: pointer;
        transition: background-color 0.2s;
        white-space: nowrap;
        color: white;

        &:hover {
            background-color: var(--gray-dark);
        }

        &:first-child {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        @media ${device.medium} {
            &:last-child {
                border-bottom-right-radius: 5px;
            }
        }
    }

    &.open {
        display: flex;
    }
`;

const Screen = styled.div`
    position: fixed;
    height: 0;
    width: 100vw;
    bottom: 0px;
    left: 0px;
    z-index: 15;

    &.open-screen {
        height: 100vh;
    }
`;

const CloseBtn = styled.button`
    background: transparent;
    border: none;
    height: 27px;
    width: 27px;
    cursor:pointer;
    position: relative;

    &::before, &::after {
        content: "";
        position: absolute;
        height: 3px;
        width: 100%;
        border-radius: 5px;
        background-color: var(--text-light);
        left: 0;
        top: 12px;
    }
    &::before {
        transform: rotate(45deg);
    }
    &::after {
        transform: rotate(-45deg);
    }

    &:hover::before, &:hover::after {
        background-color: var(--bg-main);
    }
`;