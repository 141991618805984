import styled from "styled-components";
import PageTransition from "../components/PageTransition.js";
import { FormButton } from "../styles/Button.styles.js";
import { FormInput } from "../styles/Form.styles.js";
import { useRef, useState } from "react";
import { resetPassword } from "../utils/index.js";
import { Link, useSearchParams } from "react-router-dom";

import { device } from "../styles/Responsive.style";

const ResetPassword = () => {
    const passwordRef = useRef();
    const confirmPassRef = useRef();

    const [message, setMessage] = useState("");
    const [pageParams] = useSearchParams();

    const handleResetPass = async (e) => {
        e.preventDefault();

        if(passwordRef.current.value !== confirmPassRef.current.value) {
            setMessage("Passwords must match")
            return;
        }

        const data = await resetPassword(pageParams.get("id"), pageParams.get("token"), passwordRef.current.value);

        if(data.message === "Password has been reset"){
            setMessage("Success");
        } else {
            setMessage(data.message);
        }
    }

    return (
        <>
            <SectionBlock>
                {message === "Success" ? (
                    <SuccessWrap>
                        <TickSymbol></TickSymbol>
                        <p>Password has been reset</p>
                        <LoginBtn to="/login">Ok</LoginBtn>
                    </SuccessWrap>
                ) : (
                    <ResetPasswordForm onSubmit={handleResetPass}> 
                        <h1>Reset Password</h1>
                        <FormInput>   
                            <input type="password" placeholder=" " minLength="8" maxLength="30" ref={passwordRef} required />
                            <label>Password</label>
                        </FormInput>
                        <FormInput>   
                            <input type="password" placeholder=" " ref={confirmPassRef} minLength="8" maxLength="30" required />
                            <label>Confirm Password</label>
                        </FormInput>
                        <Message>{message}</Message>
                        <FormButton type="submit">Submit</FormButton>
                    </ResetPasswordForm>
                )}
            </SectionBlock>
            <PageTransition isLoading={false} />
        </>
    )
}

export default ResetPassword;

const SectionBlock = styled.section`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${device.small} {
        align-items: flex-start;
    }
`;

const ResetPasswordForm = styled.form`
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    background-color: white;
    padding: 50px;
    border-radius: 5px;

    h1 {
        margin: 0;
        font-size: 48px;
    }

    button[type="submit"] {
        margin: 10px 0 0 0;
    }

    @media ${device.small} {
        width: 100%;
        padding: 20px;
        margin: 50px 5px 0;
    }
`;

const Message = styled.p`
    color: red;
    margin: 0;
    height: 19px;
    margin-top: 10px;
`;

const SuccessWrap = styled.div`
    display: grid;
    place-items: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    background-color: white;
    padding: 50px;
    border-radius: 5px;
    width: 230px;

    p {
        text-align: center;
        margin: 20px 0 0 0;
        font-size: 20px;
    }

    @media ${device.small} {
        margin: 50px 5px 0;
    }
`;

const TickSymbol = styled.div`
    height: 100px;
    width: 100px;
    border: 3px solid var(--green);
    border-radius: 50%;
    position: relative;

    &::before {
        position: absolute;
        content: "";
        height: 52px;
        width: 3px;
        top: calc(50% + 3px);
        left: calc(50% + 12px);
        transform: translate(-50%, -50%) rotate(45deg);
        border-radius: 5px;
        background-color: var(--green);
    }
    &::after {
        position: absolute;
        content: "";
        height: 28px;
        width: 3px;
        top: calc(50% + 12px);
        left: calc(50% - 15px);
        transform: translate(-50%, -50%) rotate(-45deg);
        border-radius: 5px;
        background-color: var(--green);
    }
`;

const LoginBtn = styled(Link)`
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    margin-top: 10px;
    cursor: pointer;
    background-color: var(--primary-gray);
    text-decoration: none;
    text-align: center;

    &:hover {
        background-color: var(--secondary-gray);
    }
`;