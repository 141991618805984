import Tick from "../../assets/icons/Tick.js";
import { useAdmin } from "../../context/AdminContext.js";
import { useUser } from "../../context/UserContext.js";
import ModalTemplate from "./ModalTemplate.js";

import styled from "styled-components";

const DeleteSuccess = ({ openDeleteSuccessModal, setOpenDeleteSuccessModal }) => {

    const { setBlurryBg } = useUser();
    const {selectedUsers, setSelectedUsers, setAllUserData, setDisplayUsers} = useAdmin();

    const closeModal = () => {
        setOpenDeleteSuccessModal(false);
        setBlurryBg(false);
        setAllUserData(prev => [...prev.filter((u) => !selectedUsers.includes(u._id))]);
        setDisplayUsers(prev => [...prev.filter((u) => !selectedUsers.includes(u._id))]);
        setSelectedUsers([]);
    }

    return (
        <ModalTemplate showModal={openDeleteSuccessModal} setShowModal={setOpenDeleteSuccessModal}>
            <ContentWrapper>
                <Tick strokeCol="#11D600" width="100px"/>
                <h1>Success!</h1>
                <p>Users have been successfully deleted.</p>
                <button onClick={closeModal}>Ok</button>
            </ContentWrapper>
        </ModalTemplate>
    )
}

export default DeleteSuccess;

const ContentWrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    
    h1, p, button {
        width: 280px;
    }
    h1 {
        font-size: 40px;
        margin: 10px;
        text-align: center;
    }
    p {
        margin: 0;
        text-align: center;
    }
    button {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        margin-top: 10px;
        cursor: pointer;
        background-color: var(--primary-gray);

        &:hover {
            background-color: var(--secondary-gray);
        }
    }
`;