import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import GilroyBold from "../assets/fonts/Gilroy-ExtraBold.woff";
import GilroyLight from "../assets/fonts/Gilroy-Light.woff";
import topLogo from "../assets/images/pdfTopLogo.png";

Font.register({ family: 'Gilroy', fonts: [
    {src: GilroyBold, fontWeight: "bold"},
    {src: GilroyLight, fontWeight: "light"}
] });

// Create styles
const styles = StyleSheet.create({
    main: {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        alignItems: "center",
        fontFamily: "Gilroy",
        fontWeight: "bold",
    	margin: "10px 20px"
    },
    key: {
        fontFamily: "Gilroy",
        flexDirection: "row",
        flexWrap: "wrap",
        margin: "0 20px 20px",
        fontSize: "10px"
    },
    keyItem: {
        fontFamily: "Gilroy",
        width: "50%",
        fontWeight: "light"
    },
    table: {
        margin: "0 10px",
        padding: "0",
        flexDirection: "column",
    },
    tableHeadings: {
        flexDirection: "row",
        width: "100%",
        backgroundColor: "#e8e8e8",
        fontSize: "9px",
        padding: "8px 0",
        fontFamily: "Gilroy",
        fontWeight: "bold"
    },
    rowLight: {
        flexDirection: "row",
        width: "100%",
        backgroundColor: "#f8f8f8",
        fontSize: "9px",
        height: "17px",
        alignItems: "center"
    },
    row: {
        flexDirection: "row",
        width: "100%",
        backgroundColor: "#f0f0f0",
        fontSize: "9px",
        height: "17px",
        alignItems: "center"
    },
    nameCol: {
        width: "30%",
        paddingLeft: "10px",
        fontFamily: "Gilroy",
        fontWeight: "light"
    },
    totalCol: {
        textAlign: "center",
        width: "20%",
        fontFamily: "Gilroy",
        fontWeight: "light"
    },
    attHeading: {
        transformOrigin: "right",
        width: "5%",
        fontFamily: "Gilroy",
        fontWeight: "light"
    },
    headingNameCol: {
        width: "30%",
        paddingLeft: "10px",
        fontFamily: "Gilroy",
        fontWeight: "bold"
    },
    headingTotalCol: {
        textAlign: "center",
        width: "20%",
        fontFamily: "Gilroy",
        fontWeight: "bold"
    },
    headingAttHeading: {
        transformOrigin: "right",
        width: "5%",
        fontFamily: "Gilroy",
        fontWeight: "bold"
    },
    section: {
        width: "50%",
        paddingHorizontal: "20px"
    },
    header: {
        fontSize: 12,
        marginBottom: 10,
        justifyContent: "center",
        color: 'white',
        backgroundColor: "#222222",
        padding: "23px 20px 20px 20px",
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        top: 20,
        right: 20,
        textAlign: 'center',
        color: 'white',
        fontFamily: "Gilroy",
    },
    topLogo: {
        width: "300px",
    },
    page: {
        paddingBottom: 25
    }
});

// Create Document Component
const ResultReportTable = ({ compName, quizName, usersData }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.header} fixed>
                    <Image style={styles.topLogo} src={topLogo} />
                </View>
                <View style={styles.main}>
                    <Text>{compName} - {quizName} Results</Text>
                </View>
                <View style={styles.key} fixed>
              		<Text style={styles.keyItem}>CE - Communication & Empathy</Text>
                  	<Text style={styles.keyItem}>CF - Creative & Flexible</Text>
                  	<Text style={styles.keyItem}>CI - Curious & Inquisitive</Text>
                  	<Text style={styles.keyItem}>DT - Detailed & Technically Minded</Text>
                  	<Text style={styles.keyItem}>EI - Efficiency & Improvement</Text>
                  	<Text style={styles.keyItem}>OL - Order & Logic</Text>
                 	<Text style={styles.keyItem}>PC - Patient & Committed</Text>
                  	<Text style={styles.keyItem}>PS - Problem Solving</Text>
                  	<Text style={styles.keyItem}>RP - Resilient & Positive</Text>
                  	<Text style={styles.keyItem}>TC - Team Player & Collaborative</Text>
              	</View>
                <View style={styles.table} >
                    <View style={styles.tableHeadings} fixed>
                        <Text style={styles.headingNameCol}>Name</Text>
                        <Text style={styles.headingAttHeading}>CE</Text>
                        <Text style={styles.headingAttHeading}>CF</Text>
                        <Text style={styles.headingAttHeading}>CI</Text>
                        <Text style={styles.headingAttHeading}>DT</Text>
                        <Text style={styles.headingAttHeading}>EI</Text>
                        <Text style={styles.headingAttHeading}>OL</Text>
                        <Text style={styles.headingAttHeading}>PC</Text>
                        <Text style={styles.headingAttHeading}>PS</Text>
                        <Text style={styles.headingAttHeading}>RP</Text>
                        <Text style={styles.headingAttHeading}>TC</Text>
                        <Text style={styles.headingTotalCol}>Total</Text>
                    </View>
                    
                    {usersData.map((userInfo, i) => {
                        return (
                            <View key={i} style={i%2 === 0 ? styles.row : styles.rowLight} break={(i >= 34) ? ((i-34)%37 === 0) ? true : false : (i === 34)?true:false }>
                                <Text style={styles.nameCol}>{userInfo.name}</Text>
                                {userInfo.scores.map((score, index) => {
                                    return(<Text key={index} style={styles.attHeading}>{score}</Text>)
                                })}
                                <Text style={styles.totalCol}>{userInfo.scores.reduce((a, b) => parseInt(a) + parseInt(b), 0)}</Text>
                            </View>
                        )
                    })}    
                </View>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>
        </Document>
    )
};

export default ResultReportTable;