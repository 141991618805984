import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = (props) => {
    const location = useLocation();
    useEffect(() => {
        //wait for page slider to finish before scolling to top
        setTimeout(() => {
            window.scrollTo({top: 0, left: 0})
        }, 500)
    }, [location])

    return (
        <>
            {props.children}
        </>
    )
}

export default ScrollToTop;