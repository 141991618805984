import styled from "styled-components";
import { useUser } from "../context/UserContext";
import { useRef } from "react";

const BlurryBackground = () => {
    const { blurryBg, setBlurryBg } = useUser();
    const bg = useRef();

    const closeCard = (e) => {
        if (bg.current === e.target) {
            setBlurryBg(false)
        }
    }

    return (
        <Background ref={bg} className={blurryBg ? "open" : ""} onClick={closeCard}></Background>
    )
}

export default BlurryBackground;

const Background = styled.div`
    height: 0;
    width: 100vw;
    backdrop-filter: blur(0px);
    background-color: rgba(255, 255, 255, 0);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    transition: height 0s linear 0.2s, backdrop-filter 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s;

    &.open {
        backdrop-filter: blur(20px);
        background-color: rgba(163, 163, 163, 0.2);
        height: 100vh;
        transition: height 0s linear 0s, backdrop-filter 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s;
    }
`;