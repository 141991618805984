import styled from "styled-components";
import Pagination from "./Pagination";
import downArrow from "../../assets/icons/down-arrow.svg";
import sideArrow from "../../assets/icons/arrow-yellow.svg";
import Tick from "../../assets/icons/Tick";
import Cross from "../../assets/icons/Cross";
import { useAdmin } from "../../context/AdminContext";
import { device } from "../../styles/Responsive.style";
import { useEffect, useState } from "react";

const Table = () => {
    const { adminData, displayUsers, setDisplayUsers, currentPage, usersPerPage, setSelectedUsers, selectedUsers, selectedGroup, setViewResultsModal, setSelectedUser } = useAdmin();
    const [sorted, setSorted] = useState("none")

    const selectAllUsers = (e) => {
        if (!e.target.checked) {
            setSelectedUsers([])
        } else {
            setSelectedUsers(displayUsers.map(u => u._id))
        }
    }

    const selectUser = (e) => {
        if (!e.target.checked) {
            setSelectedUsers(prev => prev.filter(user => user !== e.target.value))
        } else {
            setSelectedUsers(prev => [...prev, e.target.value])
        }
    }

    const sortNames = () => {
        if (sorted === "none" || sorted === "z-a") {
            setDisplayUsers(prev => [...prev.sort((a, b) => a.name.localeCompare(b.name))])
            setSorted("a-z")
        } else {
            setDisplayUsers(prev => [...prev.sort((a, b) => -1 * a.name.localeCompare(b.name))])
            setSorted("z-a")
        }
    }

    useEffect(() => {
        if (sorted === "a-z") {
            setDisplayUsers(prev => [...prev.sort((a, b) => a.name.localeCompare(b.name))])
        } else if (sorted === "z-a") {
            setDisplayUsers(prev => [...prev.sort((a, b) => -1 * a.name.localeCompare(b.name))])
        }
    }, [selectedGroup])

    const handleViewResModal = (userObj) => {
        setViewResultsModal(true);
        setSelectedUser(userObj)
    }

    return (
        <TableWrapper>
            <TableHead id="table-head">
                <span className="select"> <input onChange={(e) => selectAllUsers(e)} checked={selectedUsers.length === displayUsers.length} type="checkbox" /> </span>
                <span onClick={() => sortNames()}>Name <img src={downArrow} className={sorted === "none" || sorted === "a-z" ? "down" : "up"} alt="down arrow" /></span>
                <span>Email</span>
                <span>Group</span>
                <span>Registered</span>
                <span>Results</span>
            </TableHead>

            {displayUsers.length === 0 && (
                <NoUsers>No Users Found</NoUsers>
            )}

            {displayUsers.slice(currentPage === 1 ? 0 : (currentPage - 1) * usersPerPage, (currentPage * usersPerPage)).map((user, index) => {
                return (
                    <TableRow key={index}>
                        <span className="select"> <input type="checkbox" value={user._id} onChange={(e) => selectUser(e)} checked={selectedUsers.indexOf(user._id) !== -1} /></span>
                        <span>{user.name ? user.name : 'N/A'}</span>
                        <span>{user.email}</span>
                        <span>{(adminData.groups.find(g => g.users.includes(user._id))) ? adminData.groups.find(g => g.users.includes(user._id)).title : "N/A"}</span>

                        <span>{user.registered ? <Tick strokeCol="#11D600" width="21px" /> : <Cross strokeCol="#F96A6A" width="21px" />}</span>
                        {(user.results.find(r => r.date_completed !== null) === undefined) ? (
                            <span>No Results</span>
                        ) : (
                            <span className="viewBtn" onClick={() => handleViewResModal(user)}>View Results <img src={sideArrow} alt="arrow" /></span>
                        )}
                    </TableRow>
                )
            })}

            <TableFooter className="footer">
                <Pagination />
            </TableFooter>
        </TableWrapper>
    )
}

export default Table;

const TableWrapper = styled.section`
    border: 1px solid var(--gray-light);
    width: 100%;
    border-radius: 10px;
    padding: 10px 10px 0;
    box-sizing: border-box;

    .select {
        display: grid;
        place-items: center;
    }

    & > div:not(.footer) {
        display: grid;
        grid-template-columns: 48px 1fr 2fr 1fr 120px 130px;
    }

    @media ${device.large} {
        & > div:not(.footer) {
            grid-template-columns: 48px 1fr 1fr 130px;
            grid-template-rows: 1fr 1fr;
        }
    }

    @media ${device.medium} {
        & > div:not(.footer) {
            grid-template-columns: 48px 1fr 130px;
        }
    }

    @media ${device.small} {
        & > div:not(.footer) {
            grid-template-columns: 34px 1fr;
            grid-template-rows: 1fr 1fr 1fr;
        }

        .select {
            display: grid;
            place-items: center left;
        }
    }
`;

const TableHead = styled.div`
    background-color: var(--bg-main-light);
    text-transform: uppercase;
    color: var(--gray);
    
    border-radius: 5px;
    padding: 10px 0px;
    font-weight: 900;
    margin-bottom: 5px;

    span:nth-child(2) {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    img {
        height: 20px;
        margin-left: 10px;
        transition: transform 0.2s;
    }

    .down {
        transform: rotate(0deg);
    }
    .up {
        transform: rotate(180deg);
    }

    @media ${device.large} {
        &#table-head {
            display: none;
        }
    }
`;

const TableRow = styled.div`
    padding: 5px 0;
    position: relative;
    border-bottom: 1px solid var(--gray-light);

    &::before {
        position: absolute;
        content: "";
        height: 1px;
        width: calc(100% + 20px);
        background-color: var(--gray-light);
        left: -10px;
        bottom: -1px;
    }

    &:hover {
        background-color: var(--bg-main-light); 
    }

    span {
        overflow: hidden;
        text-overflow: ellipsis; 
        white-space: nowrap;
        font-size: var(--font-small);
        display: flex;
        align-items: center;
    }

    .viewBtn {
        display: flex;
        align-items: center;
        color: var(--accent);
        font-weight: 900;
        cursor: pointer;
    }

    img {
        height: 20px;
        margin-left: 10px;
    }

    @media ${device.large} {
        span:nth-child(1) {
            grid-row-start: 1;
            grid-row-end: span 2;
        }
        span:nth-child(2) {
            grid-column-start: 2;
            grid-row-start: 1;
        }
        span:nth-child(3) {
            grid-column-start: 2;
        }
        span:nth-child(4) {
            grid-column-start: 3;
            grid-row-start: 1;
            &::before {
                content: "Group:";
                margin-right: 10px;
            }
        }
        span:nth-child(5) {
            grid-column-start: 3;
            grid-row-start: 2;
            display: flex;
            &::before {
                content: "Registered:";
                margin-right: 10px;
            }
        }
        span:nth-child(6) {
            grid-column-start: 4;
            grid-row-start: 1;
            grid-row-end: span 2;
        }
    }

    @media ${device.medium} {
        span:nth-child(4), span:nth-child(5) {
            visibility: hidden;
            width: 0;
        }

        span:nth-child(6) {
            grid-column-start: 3;
            grid-row-start: 1;
            grid-row-end: span 2;
        }
    }

    @media ${device.small} {
        span:nth-child(1) {
            grid-row-end: span 3;
        }

        span:nth-child(6) {
            grid-column-start: 2;
            grid-row-start: 3;
        }
    }
`;

const TableFooter = styled.div`
    padding: 15px 50px;

    @media ${device.small} {
        padding: 10px 0px;
    }
`;

const NoUsers = styled.p`
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
    color: var(--gray);
    font-size: var(--font-small);
    margin: 30px 0;
`;